/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-global-assign */

import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { message } from 'antd'

import Login from './Login'
import Dashboard from './Dashboard';

/** Global function for redirect to login if session expired * */
(function () {
  const originalFetch = fetch
  fetch = function () {
    return originalFetch.apply(this, arguments).then((data) => {
      if (data.status === 401) {
        message.warn('Your session has been expired...')
        localStorage.clear()
        window.location.href = '/login'
      }
      return data
    })
  }
}())

function App () {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/login" component={Login} />
        <Route path="/dashboard" component={Dashboard} />
      </Switch>
    </Router>
  )
}

export default App
