/* eslint-disable no-unused-vars */

import React from 'react'
import { Container } from 'reactstrap'

class OrgVideo extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      organizationDetail: {
        intro_video_unique_id: ''
      }
    }
  }

  componentDidMount () {
    if (this.props.item) {
      const { organizationDetail } = this.state
      organizationDetail.intro_video_unique_id = this.props.item.intro_video_unique_id
      organizationDetail.name = this.props.item.name
      this.setState({ organizationDetail })
    }
  }

  render () {
    return (
      <Container>
        <iframe width="560" height="350" src={`https://gathering-videos-s3.twine.nyc/organization/1080/${this.state.organizationDetail.intro_video_unique_id}.mp4`} frameBorder="0" allow="accelerometer; autoplay;  gyroscope; picture-in-picture" title="Org Video" allowFullScreen="allowfullscreen" />
      </Container>
    )
  }
}

export default OrgVideo
