/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { Table } from 'reactstrap'
import ModalComposition from '../Modals/ModalComposition'

class DataTable extends Component {
  render () {
    if (this.props.items.length === 0) {
      return (
        <Table responsive hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Rules</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="6" align="center">No Records Found</td>
            </tr>
          </tbody>
        </Table>
      )
    }
    const items = this.props.items.map((item) => (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td>{item.name}</td>
        <td>{JSON.stringify(item.rules)}</td>
        <td>{(item.is_disabled === 'true' || item.is_disabled === true) ? 'No' : 'Yes'}</td>
        <td>
          <ModalComposition buttonLabel="Edit" addItemToState={this.props.addItemToState} item={item} updateState={this.props.updateState} />
        </td>
      </tr>
    ))

    return (
      <Table responsive hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Rules</th>
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
      </Table>
    )
  }
}

export default DataTable
