/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */

import React from 'react'
import { Form, FormGroup, Label, Input } from 'reactstrap'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
// import Dashboard from './dashboard.component';
import { GoogleLogin } from 'react-google-login';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Login extends React.Component {
  constructor (props) {
    super(props)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.state = {
      submit_button_text: 'Send Verification Code',
      show_loading: false,
      show_verification_code: false,
      verification_code: '',
      phone: '',
      phone_number_code: '',
      phone_number: 0,
      error_message: ''
    }
    console.log(process.env.NODE_ENV)
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  loadingVisisble (status) {
    this.setState({ show_loading: status })
  }

  responseGoogle = (response) => {
    console.log(response);
    this.loadingVisisble(true)
    this.setState({ show_verification_code: true, error_message: '' })
    // e.preventDefault()
    fetch(process.env.REACT_APP_API_ENDPOINT + '/users/oauth_login', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id_token: response.tokenId
      })
    })
      .then(response => response.json())
      .then(item => {
        console.log(item)
        if (item.message === 'Success.') {
          // this.setState({ submit_button_text: 'Verify'});
          // alert('Your login credentials verified successfully');
          window.localStorage.setItem('token', item.response_objects.token)
          this.props.history.push('/dashboard')
          // return <Redirect to="/dashboard" />;
        } else {
          this.setState({ error_message: item.message })
          confirmAlert({
            title: 'Incorrect Email',
            buttons: [
              {
                label: 'OK'
              }
            ]
          });
        }
        this.loadingVisisble(false)
      })
      .catch(err => {
        console.log(err, 'error'); this.loadingVisisble(false)
      })
  }

  handleOnChange (value, data, event) {
    const phoneNumberCode = '+' + data.dialCode
    const phoneNumber = value.replace(/[^0-9]+/g, '').slice(data.dialCode.length)
    this.setState({ phone: value, phone_number_code: phoneNumberCode, phone_number: phoneNumber })
  }

  sendVerificationCode = e => {
    this.loadingVisisble(true)
    this.setState({ show_verification_code: true, error_message: '' })
    e.preventDefault()
    fetch(process.env.REACT_APP_API_ENDPOINT + '/crud/reset-password', {
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        phone_number_code: this.state.phone_number_code,
        phone_number: this.state.phone_number
      })
    })
      .then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          this.setState({ submit_button_text: 'Verify' })
        } else {
          this.setState({ show_verification_code: false, error_message: item.message })
        }
        this.loadingVisisble(false)
      })
      .catch(err => {
        console.log(err, 'error'); this.loadingVisisble(false)
      })
  }

  loginUSer = e => {
    this.loadingVisisble(true)
    this.setState({ show_verification_code: true, error_message: '' })
    e.preventDefault()
    fetch(process.env.REACT_APP_API_ENDPOINT + '/crud/login', {
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        phone_number_code: this.state.phone_number_code,
        phone_number: this.state.phone_number,
        password: this.state.verification_code
      })
    })
      .then(response => response.json())
      .then(item => {
        console.log(item)
        if (item.message === 'Success.') {
          // this.setState({ submit_button_text: 'Verify'});
          // alert('Your login credentials verified successfully');
          window.localStorage.setItem('token', item.response_objects.token)
          this.props.history.push('/dashboard')
          // return <Redirect to="/dashboard" />;
        } else {
          this.setState({ error_message: item.message })
        }
        this.loadingVisisble(false)
      })
      .catch(err => {
        console.log(err, 'error'); this.loadingVisisble(false)
      })
  }

  componentDidMount () {
    // this.getItems()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    return (
      <div className="App">
        <div className="auth-wrapper">
          <div className="auth-inner">
            <h3>Sign In</h3>
            <h3><GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
              buttonText="Login with Google"
              onSuccess={this.responseGoogle}
              onFailure={this.responseGoogle}
              cookiePolicy={'single_host_origin'}
            /></h3>
          </div>
          {/* <div className="auth-inner">
            <Form onSubmit={this.state.show_verification_code ? this.loginUSer : this.sendVerificationCode} className="form-horizontal">
              <h3>Sign In</h3>
              <FormGroup>
                <Label>Enter Your Phone Number</Label>
                <div>
                  <PhoneInput
                    inputProps={{
                      name: 'phone',
                      required: true,
                      autoFocus: true
                    }}
                    country={'us'}
            	      enableLongNumbers={true}
                    onChange={this.handleOnChange} value={this.state.phone === null ? '' : this.state.phone}
                    onlyCountries={['us', 'in', 'ar', 'ph', 'es']}

                  />
                </div>
              </FormGroup>
              { this.state.show_verification_code
                ? <FormGroup>
                  <Label>Enter Verification Code</Label>
                  <div>
                    <Input type="text" name="verification_code" id="verification_code" onChange={this.onChange} value={this.state.verification_code === null ? '' : this.state.verification_code}></Input>
                  </div>
                </FormGroup>
                : <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
                    buttonText="Login with Google"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={'single_host_origin'}
                  />
                }
              <FormGroup>
                <button className="btn btn-primary btn-block">{this.state.submit_button_text}</button>
                <span className="login-error">{this.state.error_message}</span>
              </FormGroup>
              { this.state.show_loading
                ? <FormGroup>
                  <div id="loading">
                    <img align="absmiddle" alt="" src="images/partialLoading.gif" />&nbsp;<span></span>Processing...
                  </div>
                </FormGroup>
                : null }
            </Form>
          </div> */}
        </div>
      </div>
    )
  }
}

export default Login
