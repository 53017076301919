/* eslint-disable no-empty */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import React from 'react'
import Select from 'react-select'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { Upload, message } from 'antd'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import 'react-datepicker/dist/react-datepicker.css'
import { CONSTANTS } from '../constants'
import { UploadOutlined } from '@ant-design/icons'

let duration = 0
const backgroundImageErrorMsg = 'Image must be smaller than 5MB!'

const defaultExitPopupMsg = { title: 'Yeeha!', subTitle: 'We hope you had as much fun as we did hosting this twine.', cta_title: 'Exit the twine room', cta_url: 'https://devstudio.twine.nyc' }

class AddEditForm extends React.Component {
  constructor (props) {
    super(props)
    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.handleOptionActiveChange = this.handleOptionActiveChange.bind(this)
    this.genrateRandomNumber = this.genrateRandomNumber.bind(this)
    this.handleDropdownChange = this.handleDropdownChange.bind(this)
    this.state = {
      roomDetail: {
        id: 0,
        name: '',
        description: 'as',
        is_disabled: false,
        points_per_seat: 50,
        conversation_interval: '480',
        finding_partner_interval: '20',
        matching_interval: '6',
        is_private: false,
        subscribable_id: '',
        video: '',
        intro_video_unique_id: '',
        entry_code: '',
        duration: '',
        maxConvoPartners: 2
      },
      starts_on: new Date(),
      ends_on: new Date(),
      errorMessage: '',
      image_unique_id: '',
      background_image_unique_id: '',
      image: '',
      errorMsg: {},
      topicsList: [],
      topicsSelected: [],
      topic_id: [],
      selectedIds: [],
      topicsInitialSelected: [],
      topicsRequestIds: [],
      topicListCheck: false,
      organizationList: [],
      organizationsSelected: [],
      organization_id: [],
      organizationSelectedIds: [],
      organizationsInitialSelected: [],
      organizationsRequestIds: [],
      organizationListCheck: false,
      loading: false,
      imageUploading: false,
      searchOrgText: '',
      searchTopicsText: '',
      compositionRuleId: '',
      compositionRuleList: [],
      compRuleSelect: '',
      spaceId: 1,
      customizationEnable: true,
      customizationDesc: '',
      customDescErrorMsg: '',
      mapId: '',
      featureId: '',
      featureDisabled: true,
      exitCustomizations: {
        title: '',
        subTitle: '',
        cta_title: '',
        cta_url: ''
      }
    }
  }

  urlPatternValidation (url) {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
    return regex.test(url)
  };

  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }

  // Generate Random Number
  genrateRandomNumber () {
    const room_detail = this.state.roomDetail
    const min = 10000
    const max = 99999
    const rand = Math.floor(Math.random() * (max - min + 1) + min)
    if (this.state.roomDetail.is_private === true || this.state.roomDetail.is_private === 'true') {
      room_detail.entry_code = rand
      this.setState({
        roomDetail: room_detail
      })
    }
  }

  setStartDate = e => {
    this.setState({ starts_on: e })
  }

  setEndDate = e => {
    this.setState({ ends_on: e })
  }

  onChange = e => {
    const roomDetail = this.state.roomDetail
    roomDetail[e.target.name] = e.target.value
    this.setState({ roomDetail })
  }

  handleCustomization = e => {
    const exitCustomizations = this.state.exitCustomizations
    exitCustomizations[e.target.name] = e.target.value
    this.setState({ exitCustomizations })
  }

  handleCustomizationToggle = (e) => {
    this.setState(prevState => ({
      customizationEnable: !prevState.customizationEnable
    }))
  }

  handleDescription = e => {
    if (this.jsonValid(e.target.value)) {
      this.setState({ customDescErrorMsg: '' })
    }
    this.setState({ customizationDesc: e.target.value })
  }

  // takes dayIndex from sunday(0) to saturday(6)
  nextDate (dayIndex) {
    const today = new Date()
    today.setDate(today.getDate() + (dayIndex + (7 - today.getDay())) % 7)
    return today
  }

  nextEndDate (dayIndex, startDate) {
    const today = new Date()
    today.setDate(startDate.getDate() + (dayIndex + (7 - startDate.getDay())) % 7)
    return today
  }

  handleChangeStartDate = date => {
    this.setState({
      starts_on: date
    })
  };

  handleChangeEndDate = date => {
    this.setState({
      ends_on: date
    })
  };

  handleDropdownChange (e) {
    const roomDetail = this.state.roomDetail
    roomDetail[e.target.name] = e.target.value
    this.setState({ roomDetail })
  }

  onSelectCompRule = e => {
    this.setState({
      compRuleSelect: e
    })
  }

  // Get Active Orgs
  getOrganization (searchText) {
    if (searchText && searchText.length >= CONSTANTS.searchTextLength) {
      fetch(process.env.REACT_APP_API_ENDPOINT + `/orgs/getAllActive?searchText=${searchText}&pageSize=${CONSTANTS.pageLimit}`, {
        method: 'get',
        headers: {
          Authorization: window.localStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(items => {
          if (Array.isArray(items.response_objects)) {
            const options = items.response_objects.map((data) => {
              return { id: data.id, value: data.name, label: data.name }
            }
            )
            this.setState({
              organizationList: options
            })
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
    } else if (!searchText) {
      fetch(process.env.REACT_APP_API_ENDPOINT + `/orgs/getAllActive?searchText=${searchText}&pageSize=${CONSTANTS.pageLimit}`, {
        method: 'get',
        headers: {
          Authorization: window.localStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(items => {
          if (Array.isArray(items.response_objects)) {
            const options = items.response_objects.map((data) => {
              return { id: data.id, value: data.name, label: data.name }
            }
            )
            this.setState({
              organizationList: options
            })
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
    }
  }

  // Get Active Topics
  getTopics (searchText) {
    if (searchText && searchText.length >= CONSTANTS.searchTextLength) {
      fetch(process.env.REACT_APP_API_ENDPOINT + `/topics/getAllTopics?searchText=${searchText}&pageSize=${CONSTANTS.pageLimit}`, {
        method: 'get',
        headers: {
          Authorization: window.localStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(items => {
          if (Array.isArray(items.response_objects)) {
            const options = items.response_objects.map((data) => {
              return { id: data.id, value: data.topic, label: data.topic }
            }
            )
            this.setState({
              topicsList: options
            })
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
    } else if (!searchText) {
      fetch(process.env.REACT_APP_API_ENDPOINT + `/topics/getAllTopics?searchText=${searchText}&pageSize=${CONSTANTS.pageLimit}`, {
        method: 'get',
        headers: {
          Authorization: window.localStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(items => {
          if (Array.isArray(items.response_objects)) {
            const options = items.response_objects.map((data) => {
              return { id: data.id, value: data.topic, label: data.topic }
            }
            )
            this.setState({
              topicsList: options
            })
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
    }
  }

  validateRoomForm () {
    const roomDetail = this.state.roomDetail
    const exitCustomizations = this.state.exitCustomizations
    const error = {}
    let formValid = true

    if (!roomDetail.name) {
      error.name = 'Please Enter the Name'
      formValid = false
    }
    if (!roomDetail.conversation_interval) {
      error.conversation_interval = 'Please Select the Conversation Interval'
      formValid = false
    }
    if (roomDetail.points_per_seat < 0) {
      error.points_per_seat = 'Points Per Seat should allow positive number'
      formValid = false
    }
    if (!roomDetail.finding_partner_interval) {
      error.finding_partner_interval = 'Please Select the Finding Partner Interval'
      formValid = false
    }
    if (!roomDetail.matching_interval) {
      error.matching_interval = 'Please Select the Matching Interval'
      formValid = false
    }
    if (!roomDetail.description) {
      error.description = 'Please Enter the Description'
      formValid = false
    }
    if (roomDetail.is_private) {
      if (!roomDetail.entry_code) {
        error.entry_code = 'Please Enter the Entry code'
        formValid = false
      }
    }

    if (!this.state.customizationEnable) {
      if (!exitCustomizations.title) {
        error.title = 'Please enter the exit screen title'
        formValid = false
      }
      if (!exitCustomizations.subTitle) {
        error.subTitle = 'Please enter the exit screen sub title'
        formValid = false
      }
      if (!exitCustomizations.cta_title) {
        error.cta_title = 'Please enter the exit screen button title'
        formValid = false
      }
      if (!exitCustomizations.cta_url) {
        error.cta_url = 'Please enter the exit screen button URL'
        formValid = false
      }
      if (exitCustomizations.cta_url && !this.urlPatternValidation(exitCustomizations.cta_url)) {
        error.cta_url = 'Please enter valid URL'
        formValid = false
      }
    }

    if (!this.state.starts_on) {
      error.starts_on = 'Please Select the Start Date'
      formValid = false
    }

    if (!this.state.ends_on) {
      error.ends_on = 'Please Select the End Date'
      formValid = false
    }

    if (this.state.starts_on && this.state.ends_on) {
      const startNum = Date.parse(this.state.starts_on)
      const endNum = Date.parse(this.state.ends_on)

      if (startNum > endNum) {
        error.date_range = 'Start Date should not be greater than End Date'
        formValid = false
      }
    }

    this.setState({ errorMsg: error })
    return formValid
  }

  onSelectTopics = e => {
    const topicsSelected = []
    if (e) {
      e.forEach((value) => { topicsSelected.push(value.id) })
      this.setState({ topic_id: e, topicsSelected: topicsSelected })
    } else {
      this.setState({ topic_id: '', topicsSelected: topicsSelected })
    }
  }

  onSelectOrg = e => {
    const orgSelected = []
    if (e) {
      e.forEach((value) => { orgSelected.push(value.id) })
      this.setState({ organization_id: e, organizationSelectedIds: orgSelected })
    } else {
      this.setState({ organization_id: '', organizationSelectedIds: orgSelected })
    }
  }

  handleInputOrgChange (searchOrg) {
    this.setState({
      searchOrgText: searchOrg
    })
    this.getOrganization(searchOrg)
  }

  handleInputTopicsChange (searchTopics) {
    this.setState({
      searchTopicsText: searchTopics
    })
    this.getTopics(searchTopics)
  }

  submitFormAdd = e => {
    const startNum = Date.parse(this.state.starts_on)
    const startDate = new Date(startNum)
    startDate.setSeconds('00')
    const startDateTz = moment(startDate).tz('Etc/UTC').format()

    const endNum = Date.parse(this.state.ends_on)
    const endDate = new Date(endNum)
    endDate.setSeconds('00')
    const endDateTz = moment(endDate).tz('Etc/UTC').format()

    let conversationInterval = this.state.roomDetail.conversation_interval
    let findingPartnerInterval = this.state.roomDetail.finding_partner_interval
    let subscribableId = this.state.roomDetail.subscribable_id
    let matchingInterval = this.state.roomDetail.matching_interval
    let entry_code = this.state.roomDetail.entry_code

    if (conversationInterval < 60) {
      conversationInterval = '00:00:' + conversationInterval.toString()
    } else {
      conversationInterval = '00:' + (conversationInterval / 60).toString() + ':00'
    }
    if (findingPartnerInterval < 60) {
      findingPartnerInterval = '00:00:' + findingPartnerInterval.toString()
    } else {
      findingPartnerInterval = '00:' + (findingPartnerInterval / 60).toString() + ':00'
    }
    if (matchingInterval < 60) {
      matchingInterval = '00:00:' + matchingInterval.toString()
    } else {
      matchingInterval = '00:' + (matchingInterval / 60).toString() + ':00'
    }
    if (subscribableId === '') {
      subscribableId = null
    } else {
      subscribableId = this.state.roomDetail.subscribable_id
    }
    if (this.state.roomDetail.is_private === false || this.state.roomDetail.is_private === 'false') {
      entry_code = ''
    }
    e.preventDefault()
    if (this.validateRoomForm()) {
      this.setState({ loading: true })
      fetch(process.env.REACT_APP_API_ENDPOINT + '/rooms/add', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          name: escape(this.state.roomDetail.name),
          image_unique_id: document.getElementById('image_unique_id').value,
          background_image_unique_id: this.state.background_image_unique_id,
          description: escape(this.state.roomDetail.description),
          starts_on: startDateTz,
          ends_on: endDateTz,
          is_disabled: this.state.roomDetail.is_disabled,
          points_per_seat: Number(this.state.roomDetail.points_per_seat),
          conversation_interval: conversationInterval,
          finding_partner_interval: findingPartnerInterval,
          max_conversation_partners: this.state.roomDetail.maxConvoPartners,
          subscribable_id: subscribableId,
          matching_interval: matchingInterval,
          entry_code: entry_code,
          is_private: this.state.roomDetail.is_private,
          space_composition_rule_id: this.state.compRuleSelect.id
        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            this.addFeature(item.response_objects.id)
            this.setState({ roomId: item.response_objects.id })
            message.success('Room Added Successfully')
            this.props.addItemToState(item.response_objects)
            this.addRoomTopics(item.response_objects.id)
            this.addRoomOrganization(item.response_objects.id)
            this.setState({ loading: false })
          } else {
            message.error(item.message)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  // Add / Update room featues
  addFeature = (id) => {
    let payload
    if (this.state.mapId) {
      payload = {
        feature_id: 5,
        room_id: id,
        is_disabled: this.state.customizationEnable,
        customizations: this.state.exitCustomizations ? this.state.exitCustomizations : defaultExitPopupMsg,
        map_id: this.state.mapId
      }
    } else {
      payload = {
        feature_id: 5,
        room_id: id,
        is_disabled: this.state.customizationEnable,
        customizations: this.state.exitCustomizations ? this.state.exitCustomizations : defaultExitPopupMsg
      }
    }
    fetch(process.env.REACT_APP_API_ENDPOINT + '/rooms/addFeature', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          this.props.updateState(item.response_objects)
          this.props.toggle()
        } else {
          message.error(item.message)
          this.props.toggle()
        }
      })
      .catch(err => {
        message.error(err)
      })
  }

  // Add or Remove Orgs for given RoomId
  addRoomOrganization = (id) => {
    const organization_id = this.state.organization_id
    const organizationRequest = []
    if (this.state.organizationsInitialSelected && this.state.organizationsInitialSelected.length > 0) {
      this.state.organizationsInitialSelected.map((value) => {
        this.state.organizationSelectedIds.push({ id: value.id, organization_id: value.organization_id, is_disabled: true })
      })
      this.state.organizationSelectedIds.map((value) => {
        if (value.is_disabled) {
          organizationRequest.push(value)
        } else {
          if (this.state.organizationsRequestIds.indexOf(value) >>> -1 !== true) {
            organizationRequest.push({ id: '', organization_id: value, is_disabled: false })
          }
        }
      })
    } else {
      organization_id.map((value) => {
        organizationRequest.push({ id: '', organization_id: value.id, is_disabled: false })
      })
    }
    if (organizationRequest && organizationRequest.length > 0) {
      fetch(process.env.REACT_APP_API_ENDPOINT + '/rooms/addOrganizationRoom', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          organizationsIds: organizationRequest,
          room_id: id
        })
      }).then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {

          }
        }).catch(err => console.log(err))
    }
  }

  // Add or Remove Topics for given RoomId
  addRoomTopics = (id) => {
    const topic_id = this.state.topic_id
    const topicRequest = []
    if (this.state.topicsInitialSelected && this.state.topicsInitialSelected.length > 0) {
      this.state.topicsInitialSelected.map((value) => {
        this.state.topicsSelected.push({ id: value.id, topic_id: value.topic_id, is_disabled: true })
      })
      this.state.topicsSelected.map((value) => {
        if (value.is_disabled) {
          topicRequest.push(value)
        } else {
          if (this.state.topicsRequestIds.indexOf(value) >>> -1 !== true) {
            topicRequest.push({ id: '', topic_id: value, is_disabled: false })
          }
        }
      })
    } else {
      topic_id.map((value) => {
        topicRequest.push({ id: '', topic_id: value.id, is_disabled: false })
      })
    }
    if (topicRequest && topicRequest.length > 0) {
      fetch(process.env.REACT_APP_API_ENDPOINT + '/rooms/addTopicsRooms', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          topicsIds: topicRequest,
          room_id: id
        })
      }).then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {

          }
        }).catch(err => console.log(err))
    }
  }

  submitFormEdit = e => {
    const startNum = Date.parse(this.state.starts_on)
    const startDate = new Date(startNum)
    startDate.setSeconds('00')
    const startDateTz = moment(startDate).tz('Etc/UTC').format()

    const endNum = Date.parse(this.state.ends_on)
    const endDate = new Date(endNum)
    endDate.setSeconds('00')
    const endDateTz = moment(endDate).tz('Etc/UTC').format()

    let conversationInterval = this.state.roomDetail.conversation_interval
    let findingPartnerInterval = this.state.roomDetail.finding_partner_interval
    let subscribableId = this.state.roomDetail.subscribable_id
    let matchingInterval = this.state.roomDetail.matching_interval
    let entry_code = this.state.roomDetail.entry_code

    if (conversationInterval < 60) {
      conversationInterval = '00:00:' + conversationInterval.toString()
    } else {
      conversationInterval = '00:' + (conversationInterval / 60).toString() + ':00'
    }
    if (findingPartnerInterval < 60) {
      findingPartnerInterval = '00:00:' + findingPartnerInterval.toString()
    } else {
      findingPartnerInterval = '00:' + (findingPartnerInterval / 60).toString() + ':00'
    }
    if (matchingInterval < 60) {
      matchingInterval = '00:00:' + matchingInterval.toString()
    } else {
      matchingInterval = '00:' + (matchingInterval / 60).toString() + ':00'
    }
    if (subscribableId === '') {
      subscribableId = null
    } else {
      subscribableId = this.state.roomDetail.subscribable_id
    }
    if (this.state.roomDetail.is_private === false || this.state.roomDetail.is_private === 'false') {
      entry_code = ''
    }

    e.preventDefault()
    if (this.validateRoomForm()) {
      this.setState({ loading: true })
      fetch(process.env.REACT_APP_API_ENDPOINT + '/rooms/add', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          id: this.state.roomDetail.id,
          name: escape(this.state.roomDetail.name),
          image_unique_id: document.getElementById('image_unique_id').value,
          background_image_unique_id: this.state.background_image_unique_id,
          description: escape(this.state.roomDetail.description),
          starts_on: startDateTz,
          ends_on: endDateTz,
          is_disabled: this.state.roomDetail.is_disabled,
          points_per_seat: Number(this.state.roomDetail.points_per_seat),
          conversation_interval: conversationInterval,
          finding_partner_interval: findingPartnerInterval,
          max_conversation_partners: this.state.roomDetail.maxConvoPartners,
          subscribable_id: subscribableId,
          matching_interval: matchingInterval,
          entry_code: entry_code,
          is_private: this.state.roomDetail.is_private,
          space_composition_rule_id: this.state.compRuleSelect.id
        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            message.success('Room Updated Successfully')
            this.addFeature(this.state.roomDetail.id)
            this.props.updateState(item.response_objects)
            this.addRoomTopics(item.response_objects.id)
            this.addRoomOrganization(item.response_objects.id)
            this.setState({ loading: false })
          } else {
            message.error(item.message)
          }
        })
        .catch(err => {
          message.error(err)
        })
    }
  }

  handleOptionActiveChange (event) {
    const roomDetail = this.state.roomDetail
    roomDetail[event.target.name] = event.target.value
    this.setState({ roomDetail })
  }

  handleOptionChange (event) {
    const roomDetail = this.state.roomDetail
    roomDetail[event.target.name] = event.target.value
    this.setState({ roomDetail })
    if ((this.state.roomDetail.is_private === true || this.state.roomDetail.is_private === 'true') && this.state.roomDetail.entry_code === '') {
      this.genrateRandomNumber()
    }
  }

  handleError = (status, clearSuggestions) => {
    this.setState({ errorMessage: status }, () => {
      clearSuggestions()
    })
  };

  fileOnChange (info) {
    if (info.file.status === 'done') {
      document.getElementById('image_unique_id').value = info.file.response.image_unique_id
      message.success(`${info.file.name} file uploaded successfully`)
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  backgroundImagefileOnChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({
        imageUploading: true
      })
    } else if (info.file.status === 'done') {
      document.getElementById('background_image_unique_id').value = info.file.response.background_image_unique_id
      message.success(`${info.file.name} file uploaded successfully`)
      this.setState({
        imageUploading: false,
        background_image_unique_id: info.file.response.background_image_unique_id
      })
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  backgroundImageBeforeUpload = (file) => {
    const isJpg = file.type === 'image/jpeg' || file.type === 'image/jpg'
    if (!isJpg) {
      message.error('You can only upload JPG file!')
    }
    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      this.setState({
        imageErrorMsg: backgroundImageErrorMsg
      })
    } else {
      this.setState({
        imageErrorMsg: ''
      })
    }
    return isJpg && isLt5M
  }

  beforeUpload (file) {
    const isJpg = file.type === 'image/jpeg' || file.type === 'image/jpg'
    if (!isJpg) {
      message.error('You can only upload JPG file!')
    }
    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      message.error('Image must smaller than 5MB!')
    }
    return isJpg && isLt5M
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const file = event.target.files[0]
    this.setState({ videoFile: file })
    if (file !== null && file !== '' && file !== undefined) {
      if (file.size > 100e6) {
        this.setState({ errorMessage: 'Please upload a file smaller than 100 MB' })
        return false
      }
      const types = /(\.|\/)(mp4)$/i
      if (types.test(file.type) || types.test(file.name)) {
        let obUrl
        if (file.name.match(/\.(avi|mp3|mp4|mpeg|ogg)$/i)) {
          obUrl = URL.createObjectURL(file)
          document.getElementById('audio').setAttribute('src', obUrl)
        }
        document.getElementById('audio').addEventListener('canplaythrough', (e) => {
          duration = Math.round(e.currentTarget.duration)
          this.uploadVideo(duration)
        })
      } else {
        this.setState({ errorMessage: 'Please Choose MP4 Video Only' })
        return false
      }
    } else {
      this.setState({ errorMessage: 'Please Choose MP4 Intro Video' })
      return false
    }
  };

  uploadVideo = (duration) => {
    const formData = new FormData()
    formData.append('video', this.state.videoFile)
    formData.append('intro_video_duration', duration)
    this.setState({ loading: true })
    fetch(process.env.REACT_APP_API_ENDPOINT + `/rooms/${this.props.item.id}/videos`, {
      headers: {
        Authorization: window.localStorage.getItem('token')
      },
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          message.success('Rooms Video Upload Successfully')
          this.props.toggle()
          this.props.updateState(item.response_objects)
          this.setState({ loading: false, videoFile: '' })
          duration = 0
        } else {
          message.error(item.message)
          this.props.toggle()
        }
      })
      .catch(err => {
        message.error(err)
      })
  }

  handleDelete = (event) => {
    event.preventDefault()
    fetch(process.env.REACT_APP_API_ENDPOINT + '/rooms/removeVideo', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      },
      body: JSON.stringify({
        video_id: this.state.roomDetail.video_id,
        is_disabled: true
      })
    })
      .then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          message.success('Rooms Video Removed Successfully')
          this.props.toggle()
          this.props.updateState(item.response_objects)
        } else {
          message.error(item.message)
          this.props.toggle()
        }
      })
      .catch(err => {
        message.error(err)
      })
  }

  // Get Active Orgs By RoomId
  getOrganizationByRoomId = (id) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + `/rooms/getOrganizationByRoomId/${id}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          const orgData = []
          item.response_objects.map((value) => {
            const options = { id: value.organization_id, value: value.name, label: value.name }
            this.state.organizationSelectedIds.push(value.organization_id)
            this.state.organizationsRequestIds.push(value.organization_id)
            orgData.push(options)
          })
          this.setState({
            organizationsInitialSelected: item.response_objects,
            organization_id: orgData
          })
        }
      }).catch(err => console.log(err))
  }

  // Get Active Topics By RoomId
  getTopicsByRoomId = (id) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + `/rooms/getTopicsByRoomId/${id}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          const topicsData = []
          item.response_objects.map((value) => {
            const options = { id: value.topic_id, value: value.topic, label: value.topic }
            this.state.topicsSelected.push(value.topic_id)
            this.state.topicsRequestIds.push(value.topic_id)
            topicsData.push(options)
          })
          this.setState({
            topicsInitialSelected: item.response_objects,
            topic_id: topicsData
          })
        }
      }).catch(err => console.log(err))
  }

  // Get Active Composition rules
  getAllActiveCompositionRule = () => {
    fetch(process.env.REACT_APP_API_ENDPOINT + '/composition_rules/getAllActive', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(items => {
        if (Array.isArray(items.response_objects)) {
          const compList = []
          items.response_objects.map((data) => {
            if (data.space_id === this.state.spaceId) {
              const options = { id: data.id, value: data.name, label: data.name }
              compList.push(options)
            }
            this.setState({
              compositionRuleList: compList
            })
          })
          if (this.props.item && this.props.item.space_composition_rule_id) {
            items.response_objects.map((data) => {
              if (data.id === this.props.item.space_composition_rule_id) {
                const compRuleDetails = { id: data.id, value: data.name, label: data.name }
                this.setState({
                  compRuleSelect: compRuleDetails
                })
              }
            })
          }
        } else {
          console.log('failure')
        }
      }).catch(err => console.log(err))
  }

  componentDidMount () {
    // if item exists, populate the state with proper data
    this.getOrganization(this.state.searchOrgText)
    this.getTopics(this.state.searchTopicsText)
    this.getAllActiveCompositionRule()
    if (this.props.item) {
      const id = this.props.item.id
      this.getOrganizationByRoomId(id)
      this.getTopicsByRoomId(id)
      const startNum = Date.parse(this.props.item.starts_on)
      const startDate = new Date(startNum)

      const endNum = Date.parse(this.props.item.ends_on)
      const endDate = new Date(endNum)

      this.setState({ starts_on: startDate, ends_on: endDate })
      const roomDetail = this.state.roomDetail

      roomDetail.id = this.props.item.id
      roomDetail.name = this.props.item.name
      roomDetail.description = this.props.item.description
      roomDetail.is_disabled = this.props.item.is_disabled
      roomDetail.points_per_seat = this.props.item.points_per_seat
      roomDetail.conversation_interval = this.props.item.conversation_interval
      roomDetail.finding_partner_interval = this.props.item.finding_partner_interval
      roomDetail.maxConvoPartners = this.props.item.max_conversation_partners
      roomDetail.matching_interval = this.props.item.matching_interval
      roomDetail.is_private = this.props.item.is_private
      roomDetail.entry_code = this.props.item.entry_code
      roomDetail.intro_video_unique_id = this.props.item.intro_video_unique_id
      roomDetail.video_id = this.props.item.video_id

      const image = this.props.item.image
      const image_unique_id = this.props.item.image_unique_id
      const background_image_unique_id = this.props.item.background_image_unique_id
      const spaceId = this.props.item.space_id
      const customizationDesc = JSON.parse(this.props.item.customizations)
      const mapId = this.props.item.map_id
      const featureId = this.props.item.feature_id
      const featureDisabled = this.props.item.feature_disabled === null ? true : this.props.item.feature_disabled
      const exitCustomizations = this.state.exitCustomizations
      exitCustomizations.title = customizationDesc?.title
      exitCustomizations.subTitle = customizationDesc?.subTitle
      exitCustomizations.cta_title = customizationDesc?.cta_title
      exitCustomizations.cta_url = customizationDesc?.cta_url

      this.setState({
        roomDetail,
        image,
        image_unique_id,
        background_image_unique_id,
        spaceId,
        customizationDesc,
        mapId,
        featureDisabled,
        featureId,
        customizationEnable: featureDisabled
      })
    } else {
      const startNum = Date.parse(this.state.starts_on)
      const startDate = new Date(startNum)
      startDate.setHours('17')
      startDate.setMinutes('30')
      this.setState({ starts_on: startDate })

      const endNum = Date.parse(this.state.ends_on)
      const endDate = new Date(endNum)
      endDate.setHours('19')
      endDate.setMinutes('00')
      this.setState({ ends_on: endDate, topicListCheck: true, organizationListCheck: true })
    }
  }

  userSelectedItem = (e) => {
    this.state.userSuggestList.forEach((item) => {
      if (item.name === e) {
        this.setState({ facilitator_user_id: item.id })
      }
    })
  }

  jsonValid = (item) => {
    item = typeof item !== 'string'
      ? JSON.stringify(item)
      : item
    try {
      item = JSON.parse(item)
    } catch (e) {
      return false
    }
    if (typeof item === 'object' && item !== null) {
      return true
    }
    return false
  }

  userAutoComplete = (e) => {
    this.setState({ image_unique_id: document.getElementById('image_unique_id').value, background_image_unique_id: document.getElementById('background_image_unique_id').value })

    let userInput = e.replace(/\//g, '')
    userInput = userInput.replace('#', '')
    this.setState({ retInput: e, facilitator: userInput })
    if (userInput.length > 2) {
      fetch(process.env.REACT_APP_API_ENDPOINT + '/users/loadByName', {
        method: 'post',
        headers: {
          Authorization: window.localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          searchString: userInput
        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            const items = item.response_objects
            this.setState({ userSuggestList: items })
          } else {
            this.setState({ userSuggestList: [] })
          }
        })
        .catch(err => console.log(err))
    } else {
      this.setState({ userSuggestList: [] })
    }
  }

  render () {
    moment.tz.setDefault('Etc/UTC')
    const imageConstant = 'https://gathering-images-s3.twine.nyc/room/'
    const imageType = '.jpg'
    const intervalList = []
    for (let i = 1; i <= 10; i++) {
      intervalList.push(<option key={i}>{i}</option>)
    }
    intervalList.push(<option key={'20'}>{'20'}</option>)
    intervalList.push(<option key={'30'}>{'30'}</option>)
    intervalList.push(<option key={'60'}>{'60'}</option>)
    intervalList.push(<option key={'120'}>{'120'}</option>)
    intervalList.push(<option key={'180'}>{'180'}</option>)
    intervalList.push(<option key={'240'}>{'240'}</option>)
    intervalList.push(<option key={'300'}>{'300'}</option>)
    intervalList.push(<option key={'360'}>{'360'}</option>)
    intervalList.push(<option key={'420'}>{'420'}</option>)
    intervalList.push(<option key={'480'}>{'480'}</option>)
    intervalList.push(<option key={'540'}>{'540'}</option>)
    intervalList.push(<option key={'600'}>{'600'}</option>)
    intervalList.push(<option key={'720'}>{'720'}</option>)
    intervalList.push(<option key={'1200'}>{'1200'}</option>)
    intervalList.push(<option key={'1800'}>{'1800'}</option>)
    intervalList.push(<option key={'2400'}>{'2400'}</option>)

    const findingPartnerintervalList = []
    for (let j = 1; j <= 10; j++) {
      findingPartnerintervalList.push(<option key={j}>{j}</option>)
    }
    findingPartnerintervalList.push(<option key={'20'}>{'20'}</option>)
    findingPartnerintervalList.push(<option key={'30'}>{'30'}</option>)
    findingPartnerintervalList.push(<option key={'60'}>{'60'}</option>)
    findingPartnerintervalList.push(<option key={'120'}>{'120'}</option>)
    findingPartnerintervalList.push(<option key={'180'}>{'180'}</option>)
    findingPartnerintervalList.push(<option key={'240'}>{'240'}</option>)
    findingPartnerintervalList.push(<option key={'300'}>{'300'}</option>)
    findingPartnerintervalList.push(<option key={'360'}>{'360'}</option>)
    findingPartnerintervalList.push(<option key={'420'}>{'420'}</option>)
    findingPartnerintervalList.push(<option key={'480'}>{'480'}</option>)
    findingPartnerintervalList.push(<option key={'540'}>{'540'}</option>)
    findingPartnerintervalList.push(<option key={'600'}>{'600'}</option>)
    findingPartnerintervalList.push(<option key={'1200'}>{'1200'}</option>)
    findingPartnerintervalList.push(<option key={'1800'}>{'1800'}</option>)
    findingPartnerintervalList.push(<option key={'2400'}>{'2400'}</option>)

    const maxConversationPartnerList = []
    for (let k = 2; k <= 5; k++) {
      maxConversationPartnerList.push(<option key={k}>{k}</option>)
    }

    const props = {
      name: 'image',
      action: process.env.REACT_APP_API_ENDPOINT + '/rooms/upload',
      method: 'PUT',
      accept: '.jpg, .jpeg',
      headers: {
        Authorization: window.localStorage.getItem('token')
      },
      multiple: false,
      showUploadList: { showDownloadIcon: false }
    }
    const backgroundImageProps = {
      name: 'image',
      action: process.env.REACT_APP_API_ENDPOINT + '/rooms/uploadBackgroundImage',
      method: 'PUT',
      accept: '.jpg, .jpeg',
      headers: {
        Authorization: window.localStorage.getItem('token')
      },
      multiple: false,
      showUploadList: { showDownloadIcon: false, showRemoveIcon: false }
    }
    return (
      this.state.loading
        ? <div id="loadingContent">
          <img align="absmiddle" alt="" src="../images/loading.gif" />
        </div>
        : <Container>
          <Form className="form-horizontal">
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label for="organizations" className="control-label col-sm-12">Organizations</Label>
                  <div className="col-sm-12">
                    <Select
                      options={this.state.organizationList && this.state.organizationList.length ? this.state.organizationList : []}
                      value={this.state.organization_id}
                      onChange={this.onSelectOrg}
                      isMulti
                      isSearchable={true}
                      onInputChange={(inputText) => this.handleInputOrgChange(inputText)}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label for="topics" className="control-label col-sm-12">Topics</Label>
                  <div className="col-sm-12">
                    <Select
                      options={this.state.topicsList && this.state.topicsList.length > 0 ? this.state.topicsList : []}
                      value={this.state.topic_id}
                      onChange={this.onSelectTopics}
                      isMulti
                      onInputChange={(inputText) => this.handleInputTopicsChange(inputText)}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="name" className="control-label col-sm-4">Name</Label>
                  <div className="col-sm-12"><Input type="text" name="name" id="name" onChange={this.onChange} value={this.state.roomDetail.name === null ? '' : this.state.roomDetail.name} />
                    <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.name}</span>
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="starts_on" className="control-label col-sm-8">Starts On</Label>
                  <div className="col-sm-12">
                    <DatePicker
                      selected={this.state.starts_on}
                      onChange={date => this.setStartDate(date)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      className="form-control"
                    />
                  </div>
                  <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.starts_on}</span>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="ends_on" className="control-label col-sm-8">Ends On</Label>
                  <div className="col-sm-12">
                    <DatePicker
                      selected={this.state.ends_on}
                      onChange={date => this.setEndDate(date)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      className="form-control"
                    />

                  </div>
                  <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.ends_on}</span>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="total_seats" className="control-label col-sm-8">Points Per Seat</Label>
                  <div className="col-sm-12"><Input type="number" name="points_per_seat" id="points_per_seat" onChange={this.onChange} value={this.state.roomDetail.points_per_seat === null ? '' : this.state.roomDetail.points_per_seat} onInput={this.maxLengthCheck} maxLength={5} />
                    <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.points_per_seat}</span>
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="finding_partner_interval" className="control-label col-sm-12">Finding Partner Interval (In Seconds)</Label>
                  <div className="col-sm-12">
                    <select name="finding_partner_interval" className="form-control" onChange={this.onChange} value={this.state.roomDetail.finding_partner_interval}>
                      <option value=''>Select Interval</option>
                      {findingPartnerintervalList}
                    </select>
                    <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.finding_partner_interval}</span>
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="conversation_interval" className="control-label col-sm-12" >Conversation Interval (In Seconds)</Label>
                  <div className="col-sm-12">
                    <select name="conversation_interval" className="form-control" onChange={this.onChange} value={this.state.roomDetail.conversation_interval}>
                      <option value=''>Select Interval</option>
                      {intervalList}
                    </select>
                    <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.conversation_interval}</span>
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="matching_interval" className="control-label col-sm-12" >Matching Interval (In Seconds)</Label>
                  <div className="col-sm-12">
                    <select name="matching_interval" className="form-control" onChange={this.onChange} value={this.state.roomDetail.matching_interval}>
                      <option value=''>Select Interval</option>
                      {intervalList}
                    </select>
                    <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.matching_interval}</span>
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="Active" className="control-label col-sm-4">Private</Label>
                  <div className="col-sm-12">
                    <input type="radio" value="true" name="is_private" id="privateYes" checked={this.state.roomDetail.is_private === true || this.state.roomDetail.is_private === 'true'} onChange={this.handleOptionChange} /> Yes
                &nbsp;<input type="radio" value="false" name="is_private" id="privateNo" checked={this.state.roomDetail.is_private === false || this.state.roomDetail.is_private === 'false'} onChange={this.handleOptionChange} /> No
                    <div><span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.is_private}</span></div>
                  </div>
                </FormGroup>
              </Col>

              {(this.state.roomDetail.is_private === true || this.state.roomDetail.is_private === 'true') &&
                <Col xs="6">
                  <FormGroup>
                    <Label for="total_seats" className="control-label col-sm-8">Entry Code</Label>
                    <div className="col-sm-12"><Input type="text" name="entry_code" id="entry_code" onChange={this.onChange} maxLength={5} value={this.state.roomDetail.entry_code === null ? '' : this.state.roomDetail.entry_code} />
                      <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.entry_code}</span>
                    </div>
                  </FormGroup>
                </Col>
              }

              <Col xs="6">
                <FormGroup>
                  <Label for="description" className="control-label col-sm-4">Description</Label>
                  <div className="col-sm-12"><textarea className="form-control" name="description" id="description" onChange={this.onChange} value={this.state.roomDetail.description === null ? '' : this.state.roomDetail.description} />
                    <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.description}</span>
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="max_convo_partners" className="control-label col-sm-12">Guests per conversation (up to 5)</Label>
                  <div className="col-sm-12">
                    <select name="maxConvoPartners" className="form-control" onChange={this.onChange} value={this.state.roomDetail.maxConvoPartners}>
                      {maxConversationPartnerList}
                    </select>
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="comp-rule" className="control-label col-sm-12">Composition Rule</Label>
                  <div className="col-sm-12">
                    <Select
                      options={this.state.compositionRuleList}
                      value={this.state.compRuleSelect}
                      onChange={this.onSelectCompRule}
                      isMulti={false}
                      isSearchable={true}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="Active" className="control-label col-sm-4">Active</Label>
                  <div className="col-sm-12">
                    <input type="radio" value="false" name="is_disabled" id="activeYes" checked={this.state.roomDetail.is_disabled === false || this.state.roomDetail.is_disabled === 'false'} onChange={this.handleOptionActiveChange} /> Yes
                &nbsp;<input type="radio" value="true" name="is_disabled" id="activeNo" checked={this.state.roomDetail.is_disabled === true || this.state.roomDetail.is_disabled === 'true'} onChange={this.handleOptionActiveChange} /> No
                    <div><span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.is_disabled}</span></div>
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="image" className="control-label col-sm-4">Image</Label>
                  <div className="col-sm-12">
                    <Upload {...props}
                      beforeUpload={this.beforeUpload}
                      onChange={this.fileOnChange}>
                      <Button type="button">
                        <UploadOutlined /> Click to Upload
                      </Button>
                    </Upload>
                    <Input type="hidden" name="image_unique_id" id="image_unique_id" onChange={this.onChange} value={this.state.image_unique_id === null ? '' : this.state.image_unique_id} />
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="image" className="control-label col-sm-12">Background Image</Label>
                  <div className="col-sm-12">
                    {this.state.imageUploading
                      ? <div><p className='text-primary'>Uploading ....</p></div>
                      : this.state.background_image_unique_id
                        ? <div><img
                        style={{ width: 75, height: 75 }}
                        src={imageConstant + this.state.background_image_unique_id + imageType}
                        alt="backgroundImage"
                      /> <br /> <br /> </div>
                        : ''}
                    <Upload {...backgroundImageProps}
                      beforeUpload={this.backgroundImageBeforeUpload}
                      onChange={this.backgroundImagefileOnChange}>
                      <Button type="button">
                        <UploadOutlined /> Click to Upload
                      </Button>
                    </Upload>
                    {this.state.imageErrorMsg
                      ? <div>
                      <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.imageErrorMsg}</span>
                    </div>
                      : ''}
                    <Input type="hidden" name="background_image_unique_id" id="background_image_unique_id" onChange={this.onChange} value={this.state.background_image_unique_id === null ? '' : this.state.background_image_unique_id} />
                  </div>
                </FormGroup>
              </Col>

              {this.props.item
                ? <>
                {this.props.item.intro_video_unique_id === null
                  ? <Col xs="6">

                    <FormGroup>
                      <Label for="image" className="control-label col-sm-4">Intro Video</Label>
                      <div className="col-sm-12">
                        <input type="file" name="file" id='fup' onChange={this.handleSubmit} />
                        <input type='hidden' name='f_du' id='f_du' size='5' />
                        <audio id='audio'></audio>

                      </div>
                      {this.state.errorMessage === null || this.state.errorMessage === '' ? '' : <div style={{ marginTop: '2%', marginBottom: '-2%' }}><span style={{ color: '#d31820', fontSize: '14', textAlign: 'center', marginLeft: '4%' }}>{this.state.errorMessage}</span></div>}
                    </FormGroup>
                  </Col>

                  : <Col xs="6">

                    <FormGroup>
                      <Label for="image" className="control-label col-sm-4">Intro Video</Label>
                      <div className="col-sm-12">

                        <Button
                          color="primary"
                          disabled
                        >
                          <i className="fa fa-play" style={{ fontSize: '18px' }}></i>

                        </Button>
                        <i className="fa fa-trash" style={{ fontSize: '18px', marginLeft: '3%' }} onClick={this.handleDelete}></i>

                      </div>
                    </FormGroup>
                  </Col>
                }
              </>
                : null}

              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="customization" className='control-label col-sm-12'>Enable Exit Screen Customizations</Label>
                  <label className="col-sm-12 switch" style={{ marginLeft: '2%' }}>
                    <input type='checkbox' checked={!this.state.customizationEnable} onChange={this.handleCustomizationToggle} />
                    <span className="slider round" style={{ width: '47%' }}></span>
                  </label>
                </FormGroup>
              </Col>

              {!this.state.customizationEnable &&
              <>
                <Col xs="6">
                  <FormGroup>
                    <Label for="name" className="control-label col-sm-4">Title</Label>
                    <div className="col-sm-12"><Input placeholder='Yeeha!' type="text" name="title" id="exit-tile" onChange={this.handleCustomization} value={this.state.exitCustomizations.title} maxLength="200" />
                      <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.title}</span>
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label for="name" className="control-label col-sm-4">Sub Title</Label>
                    <div className="col-sm-12"><Input placeholder='We hope you had as much fun...' type="text" name="subTitle" id="exit-sub-title" onChange={this.handleCustomization} value={this.state.exitCustomizations.subTitle} maxLength="200" />
                      <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.subTitle}</span>
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label for="name" className="control-label col-sm-4">Button Title</Label>
                    <div className="col-sm-12"><Input placeholder='Exit the twine room' type="text" name="cta_title" id="button-title" onChange={this.handleCustomization} value={this.state.exitCustomizations.cta_title} maxLength="200" />
                      <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.cta_title}</span>
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label for="name" className="control-label col-sm-4">Button URL</Label>
                    <div className="col-sm-12"><Input placeholder='https://devstudio.twine.nyc' type="text" name="cta_url" id="cta-url" onChange={this.handleCustomization} value={this.state.exitCustomizations.cta_url} maxLength="200" />
                      <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.cta_url}</span>
                    </div>
                  </FormGroup>
                </Col>
              </>}

            </Row>

            <FormGroup>
              <div className="col-sm-2"></div>
              <div className="col-sm-10"><Button className="btn-success" onClick={this.props.item ? this.submitFormEdit : this.submitFormAdd} disabled={this.state.imageUploading}>Submit</Button></div>
              <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.date_range}</span>
            </FormGroup>
          </Form>
        </Container>
    )
  }
}

export default AddEditForm
