/* eslint-disable no-unused-vars */

import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'

class FileImportErrorsModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modal: false
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
  }

  render () {
    const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>

    let button = ''
    const title = 'File Errors'

    button = <Button
      color="primary"
      onClick={this.toggle}
      style={{ float: 'left', marginRight: '10px' }}>View
    </Button>

    return (
      <div>
        {button}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog-medium">
          <ModalHeader toggle={this.toggle} close={closeBtn}>{title}</ModalHeader>
          <ModalBody>
            <div>{this.props.errors}</div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default FileImportErrorsModal
