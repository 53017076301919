/* eslint-disable no-unused-vars */
/* eslint-disable */

import React from 'react'
import { Button, Form, FormGroup, Label } from 'reactstrap'
import { message } from 'antd'
import Select from 'react-select'

class AddEditForm extends React.Component {
  constructor (props) {
    super(props)
    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.handleBuddyQuestionOptionChange = this.handleBuddyQuestionOptionChange.bind(this)
    this.handleDropdownChange = this.handleDropdownChange.bind(this)
    this.state = {
      questionDetail: {
        id: 0,
        question: '',
        is_disabled: ''
      },
      loading: false,
      categoryList: [],
      topicList: [],
      errorMsg: {},
      topicSelect: '',
      categorySelect: ''
    }
  }

  validateQuestionForm () {
    const questionDetail = this.state.questionDetail
    const error = {}
    let formValid = true

    if (!this.state.categorySelect) {
      error.categorySelect = 'Please select the Category'
      formValid = false
    }
    if (!this.state.topicSelect) {
      error.topicSelect = 'Please select the Topic'
      formValid = false
    }
    if (questionDetail.question === '' || questionDetail.question === null) {
      error.question = 'Please Enter the Question'
      formValid = false
    }

    if (questionDetail.is_disabled === '' || questionDetail.is_disabled === null) {
      error.is_disabled = 'Please Choose the Active'
      formValid = false
    }    

    this.setState({ errorMsg: error })
    return formValid
  }

  onChange = e => {
    const questionDetail = this.state.questionDetail
    questionDetail[e.target.name] = e.target.value
    this.setState({ questionDetail })
  }

  getCategory () {
    fetch(process.env.REACT_APP_API_ENDPOINT + '/categories/getAllActive', {
      method: 'get',
      headers: {
        Authorization: window.localStorage.getItem('token')
      }
    })
      .then(response => response.json())
      .then(items => {
        if (Array.isArray(items.response_objects)) {
          const options = items.response_objects.map((data) => {
            return { id: data.id, value: data.category, label: data.category }
          })
          this.setState({
            categoryList: options
          })
        }
        if (this.props.item && this.props.item.category_id) {
          items.response_objects.map((data) => {
            if (data.id === this.props.item.category_id) {
              const categoryDetails = { id: data.id, value: data.category, label: data.category }
              this.setState({
                categorySelect: categoryDetails
              })
            }
          })
        }
      })
      .catch(err => console.log(err))
  }

  getTopic (selectedCategoryId) {
    fetch(process.env.REACT_APP_API_ENDPOINT + '/topics/getAllTopicsByCategory', {
      method: 'get',
      headers: {
        Authorization: window.localStorage.getItem('token')
      }
    })
      .then(response => response.json())
      .then(items => {
        if (Array.isArray(items.response_objects)) {
          let topicsList = items.response_objects
          if (selectedCategoryId) {
            topicsList = topicsList.filter(item => item.category_id == selectedCategoryId)
          }
          const options = topicsList.map((data) => {
            return { id: data.id, value: data.topic, label: data.topic }
          })
          this.setState({
            topicList: options,
            selected: ''
          })
          if (this.props.item && this.props.item.topic_id) {
            items.response_objects.map((data) => {
              if (data.id === this.props.item.topic_id) {
                const topicDetails = { id: data.id, value: data.topic, label: data.topic }
                this.setState({
                  topicSelect: topicDetails
                })
              }
            })
          }
        } else {
          console.log('failure')
        }
      })
      .catch(err => console.log(err))
  }

  submitFormAdd = e => {
    e.preventDefault()
    if (!this.validateQuestionForm()) {
    } else {
      this.setState({ loading: true })
      fetch(process.env.REACT_APP_API_ENDPOINT + '/questions/add', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          category_id: this.state.categorySelect.id,
          topic_id: this.state.topicSelect.id,
          question: escape(this.state.questionDetail.question),
          is_disabled: this.state.questionDetail.is_disabled
        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            message.success('Question Added Successfully')
            this.props.addItemToState(item.response_objects)
            this.props.toggle()
            this.setState({ loading: false })
            window.location.reload()
          } else {
            message.error(item.message)
          }
        })
        .catch(err => {
          message.error(err)
        })
    }
  }

  submitFormEdit = e => {
    e.preventDefault()
    if (!this.validateQuestionForm()) {
    } else {
      this.setState({ loading: true })
      fetch(process.env.REACT_APP_API_ENDPOINT + '/questions/add', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          id: this.state.questionDetail.id,
          category_id: this.state.categorySelect.id,
          topic_id: this.state.topicSelect.id,
          question: escape(this.state.questionDetail.question),
          is_disabled: this.state.questionDetail.is_disabled        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            message.success('Question Updated Successfully')
            this.props.updateState(item.response_objects)
            this.props.toggle()
            this.setState({ loading: false })
          } else {
            message.error(item.message)
          }
        })
        .catch(err => {
          message.error(err)
        })
    }
  }

  handleOptionChange (event) {
    const questionDetail = this.state.questionDetail
    questionDetail[event.target.name] = event.target.value
    this.setState({ questionDetail })
  }

  handleBuddyQuestionOptionChange (event) {
    const questionDetail = this.state.questionDetail
    questionDetail[event.target.name] = event.target.value
    this.setState({ questionDetail })
  }

  handleDropdownChange (e) {
    const questionDetail = this.state.questionDetail
    questionDetail[e.target.name] = e.target.value
    this.setState({ questionDetail })
  }

  onSelectTopic = e => {
    this.setState({
      topicSelect: e
    })
  }

  onSelectCategory = e => {
    this.setState({
      categorySelect: e
    })
    this.getTopic(e.id)
  }

  componentDidMount () {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const questionDetail = this.state.questionDetail
      questionDetail.id = this.props.item.id
      questionDetail.category_id = this.props.item.category_id
      questionDetail.topic_id = this.props.item.topic_id
      questionDetail.question = this.props.item.question
      questionDetail.is_disabled = this.props.item.is_disabled      

      this.setState({ questionDetail })
      this.getTopic(this.props.item.category_id)
    }
    this.getCategory()
  }

  render () {
    return (
      this.state.loading
        ? <div id="loadingContent">
          <img align="absmiddle" alt="" src="../images/loading.gif" />
        </div>
        : <Form onSubmit={this.props.item ? this.submitFormEdit : this.submitFormAdd} className="form-horizontal">
          <FormGroup>
            <Label for="category_id" className="control-label col-sm-2">Category</Label>
            <div className="col-sm-10">
              <Select
                options={this.state.categoryList}
                value={this.state.categorySelect}
                onChange={this.onSelectCategory}
                isMulti={false}
                isSearchable={true}
              />
              {!this.state.categorySelect ? <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.categorySelect}</span> : '' }
            </div>
          </FormGroup>

          <FormGroup>
            <Label for="topic_id" className="control-label col-sm-2">Topic</Label>
            <div className="col-sm-10">
              <Select
                options={this.state.topicList}
                value={this.state.topicSelect}
                onChange={this.onSelectTopic}
                isMulti={false}
                isSearchable={true}
              />
              {!this.state.topicSelect ? <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.topicSelect}</span> : '' }
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="question" className="control-label col-sm-2">Question</Label>
            <div className="col-sm-10"><textarea className="form-control" name="question" id="question" onChange={this.onChange} value={this.state.questionDetail.question === null ? '' : this.state.questionDetail.question} />
              <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.question}</span>

            </div>
          </FormGroup>
          <FormGroup>
            <Label for="Active" className="control-label col-sm-2">Active</Label>
            <div className="col-sm-10">
              <input type="radio" value="false" name="is_disabled" id="activeYes" checked={this.state.questionDetail.is_disabled === false || this.state.questionDetail.is_disabled === 'false'} onChange={this.handleOptionChange} /> Yes
                &nbsp;<input type="radio" value="true" name="is_disabled" id="activeNo" checked={this.state.questionDetail.is_disabled === true || this.state.questionDetail.is_disabled === 'true'} onChange={this.handleOptionChange} /> No
              <div><span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.is_disabled}</span></div>
            </div>
          </FormGroup>          
          <FormGroup>
            <div className="col-sm-1"></div>
            <div className="col-sm-10"><Button className="btn-success">Submit</Button></div>
          </FormGroup>
        </Form>
    )
  }
}

export default AddEditForm
