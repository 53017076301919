/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { Table } from 'reactstrap'
import moment from 'moment-timezone'
import { DownloadOutlined } from '@ant-design/icons'
import FileImportErrorsModal from '../Modals/fileImportErrorsModal'

class DataTable extends Component {
  render () {
    const filePath = 'https://the-gathering-imports.s3.amazonaws.com/users/'
    const fileType = '.csv'
    moment.tz.setDefault('Etc/UTC')
    if (this.props.items.length === 0) {
      return (
        <Table responsive hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Type</th>
              <th>Completed</th>
              <th>Active</th>
              <th>Created Date & Time</th>
              <th>View</th>
              <th>Errors</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="8" align="center">No Records Found</td>
            </tr>
          </tbody>
        </Table>
      )
    } else {
      const items = this.props.items.map((item) => {
        let uploadFile
        if (item.file_unique_id) {
          uploadFile = <a
            href={filePath + item.file_unique_id + fileType}
          ><DownloadOutlined /></a>
        }
        const createdDate = moment(item.created_at).local().format('MM/DD/YYYY HH:mm a')
        const errors = item.errors
        return (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{(item.type_id === 1 ? 'Users Import' : 'Rooms Import')}</td>
            <td>{(item.is_completed === 'true' || item.is_completed === true) ? 'Yes' : 'No'}</td>
            <td>{(item.is_disabled === 'true' || item.is_disabled === true) ? 'No' : 'Yes'}</td>
            <td>{createdDate}</td>
            <td>{uploadFile}</td>
            <td>{(errors
              ? <FileImportErrorsModal item={item} errors={errors} />
              : '-')}
            </td>
          </tr>)
      })
      return (
        <Table responsive hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Type</th>
              <th>Completed</th>
              <th>Active</th>
              <th>Created Date & Time</th>
              <th>View</th>
              <th>Errors</th>
            </tr>
          </thead>
          <tbody>
            {items}
          </tbody>
        </Table>
      )
    }
  }
}

export default DataTable
