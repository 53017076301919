/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { Table } from 'reactstrap'
import moment from 'moment-timezone'
import ModalForm from '../Modals/space'
import ModalForms from '../Modals/spaceVideo'

class DataTable extends Component {
  render () {
    const imageConstant = 'https://space-images-s3.twine.nyc/space/'
    const imageType = '.jpg'
    moment.tz.setDefault('Etc/UTC')
    if (this.props.items.length === 0) {
      return (
        <Table responsive hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Image</th>
              <th>Video</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="4" align="center">No Records Found</td>
            </tr>
          </tbody>
        </Table>
      )
    } else {
      const items = this.props.items.map((item) => {
        let spaceImage
        if ((item.image_unique_id)) {
          spaceImage = <img
            style={{ width: 50, height: 50 }}
            src={imageConstant + item.image_unique_id + imageType}
            alt=""
          />
        }
        return (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.description}</td>
            <td>{spaceImage}</td>
            <td>
              {item.intro_unique_id && <ModalForms item={item} updateState={this.props.updateState} />}
            </td>
            <td>{(item.is_disabled === 'true' || item.is_disabled === true) ? 'No' : 'Yes'}</td>
            <td>
              <ModalForm buttonLabel="Edit" item={item} updateState={this.props.updateState} />
              {' '}
            </td>
          </tr>)
      })
      return (
        <Table responsive hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Image</th>
              <th>Video</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {items}
          </tbody>
        </Table>
      )
    }
  }
}

export default DataTable
