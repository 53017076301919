/* eslint-disable no-unused-vars */
/* eslint-disable */

import React from 'react'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { message } from 'antd'
class AddEditForm extends React.Component {
  constructor (props) {
    super(props)
    this.handleOptionChange = this.handleOptionChange.bind(this)

    this.state = {
      categoryDetail: {
        id: 0,
        category: '',
        is_disabled: ''
      },
      loading: false,
      errorMsg: {}
    }
  }

  validateCategoryForm () {
    const categoryDetail = this.state.categoryDetail
    const error = {}
    let formValid = true

    if (categoryDetail.category === '' || categoryDetail.category === null) {
      error.category = 'Please enter the Category'
      formValid = false
    }

    if (categoryDetail.is_disabled === '' || categoryDetail.is_disabled === null) {
      error.is_disabled = 'Please Choose the Active'
      formValid = false
    }

    this.setState({ errorMsg: error })
    return formValid
  }

  onChange = e => {
    const categoryDetail = this.state.categoryDetail
    categoryDetail[e.target.name] = e.target.value
    this.setState({ categoryDetail })
  }

  submitFormAdd = e => {
    e.preventDefault()

    if (!this.validateCategoryForm()) {
    } else {
      this.setState({ loading: true })
      fetch(process.env.REACT_APP_API_ENDPOINT + '/categories/add', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          category: escape(this.state.categoryDetail.category),
          is_disabled: this.state.categoryDetail.is_disabled
        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            message.success('Category Added Successfully')
            this.props.toggle()
            this.props.addItemToState(item.response_objects)
            this.setState({ loading: false })
          } else {
            message.error(item.message)
            this.props.toggle()
          }
        })
        .catch(err => {
          message.error(err)
        })
    }
  }

  submitFormEdit = e => {
    e.preventDefault()
    if (!this.validateCategoryForm()) {
    } else {
      this.setState({ loading: true })

      fetch(process.env.REACT_APP_API_ENDPOINT + '/categories/add', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          id: this.state.categoryDetail.id,
          category: escape(this.state.categoryDetail.category),
          is_disabled: this.state.categoryDetail.is_disabled
        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            message.success('Category Updated Successfully')
            this.props.toggle()
            this.props.updateState(item.response_objects)
            this.setState({ loading: false })
          } else {
            message.error(item.message)
            this.props.toggle()
          }
        })
        .catch(err => {
          message.error(err)
        })
    }
  }

  handleOptionChange (event) {
    const categoryDetail = this.state.categoryDetail
    categoryDetail[event.target.name] = event.target.value
    this.setState({ categoryDetail })
  }

  componentDidMount () {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const categoryDetail = this.state.categoryDetail
      categoryDetail.id = this.props.item.id
      categoryDetail.category = this.props.item.category
      categoryDetail.is_disabled = this.props.item.is_disabled
      this.setState({ categoryDetail })
    }
  }

  render () {
    return (
      this.state.loading
        ? <div id="loadingContent">
          <img align="absmiddle" alt="" src="../images/loading.gif" />
        </div>
        : <Form onSubmit={this.props.item ? this.submitFormEdit : this.submitFormAdd} className="form-horizontal">
          <FormGroup>
            <Label for="category" className="control-label col-sm-2">Category</Label>
            <div className="col-sm-10">
              <Input type="text" name="category" id="category" onChange={this.onChange} value={this.state.categoryDetail.category} />
              <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.category}</span>
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="Active" className="control-label col-sm-2">Active</Label>
            <div className="col-sm-10">
              <input type="radio" value="false" name="is_disabled" id="activeYes" checked={this.state.categoryDetail.is_disabled === false || this.state.categoryDetail.is_disabled === 'false'} onChange={this.handleOptionChange} /> Yes
                &nbsp;<input type="radio" value="true" name="is_disabled" id="activeNo" checked={this.state.categoryDetail.is_disabled === true || this.state.categoryDetail.is_disabled === 'true'} onChange={this.handleOptionChange} /> No
              <div><span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.is_disabled}</span></div>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-sm-10"><Button className="btn-success">Submit</Button></div>
          </FormGroup>
        </Form>
    )
  }
}

export default AddEditForm
