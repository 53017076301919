/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import React from 'react'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { message } from 'antd'
class AddEditForm extends React.Component {
  constructor (props) {
    super(props)
    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.handleOptionHiddenChange = this.handleOptionHiddenChange.bind(this)
    this.handleDropdownChange = this.handleDropdownChange.bind(this)
    this.state = {
      topicDetail: {
        id: 0,
        category_id: '',
        topic: '',
        is_hidden: '',
        is_disabled: ''
      },
      categoryList: [],
      errorMsg: {},
      loading: false
    }
  }

  validateTopicForm () {
    const topicDetail = this.state.topicDetail
    const error = {}
    let formValid = true

    if (topicDetail.category_id === '' || topicDetail.category_id === null) {
      error.category_id = 'Please Select the Category'
      formValid = false
    }

    if (topicDetail.topic === '' || topicDetail.topic === null) {
      error.topic = 'Please Enter the Topic'
      formValid = false
    }

    if (topicDetail.is_hidden === '' || topicDetail.is_hidden === null) {
      error.is_hidden = 'Please Choose the Hidden'
      formValid = false
    }

    if (topicDetail.is_disabled === '' || topicDetail.is_disabled === null) {
      error.is_disabled = 'Please Choose the Active'
      formValid = false
    }

    this.setState({ errorMsg: error })
    return formValid
  }

  onChange = e => {
    const topicDetail = this.state.topicDetail
    topicDetail[e.target.name] = e.target.value
    this.setState({ topicDetail })
  }

  getCategory () {
    fetch(process.env.REACT_APP_API_ENDPOINT + '/categories/getAllActive', {
      method: 'get',
      headers: {
        Authorization: window.localStorage.getItem('token')
      }
    })
      .then(response => response.json())
      .then(items => {
        if (Array.isArray(items.response_objects)) {
          const options = items.response_objects.map((data) =>
            <option key={data.id} value={data.id}>{data.category}</option>
          )
          this.setState({
            categoryList: options
          })
        } else {
          console.log('failure')
        }
      })
      .catch(err => console.log(err))
  }

  submitFormAdd = e => {
    e.preventDefault()
    if (!this.validateTopicForm()) {
    } else {
      this.setState({ loading: true })
      fetch(process.env.REACT_APP_API_ENDPOINT + '/topics/add', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          category_id: this.state.topicDetail.category_id,
          topic: escape(this.state.topicDetail.topic),
          is_hidden: this.state.topicDetail.is_hidden,
          is_disabled: this.state.topicDetail.is_disabled
        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            message.success('Topic Added Successfully')
            this.props.addItemToState(item.response_objects)
            this.props.toggle()
            this.setState({ loading: false })
            window.location.reload()
          } else {
            message.error(item.message)
          }
        })
        .catch(err => {
          message.error(err)
        })
    }
  }

  submitFormEdit = e => {
    e.preventDefault()
    if (!this.validateTopicForm()) {
    } else {
      this.setState({ loading: true })
      fetch(process.env.REACT_APP_API_ENDPOINT + '/topics/add', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          id: this.state.topicDetail.id,
          category_id: this.state.topicDetail.category_id,
          topic: escape(this.state.topicDetail.topic),
          is_hidden: this.state.topicDetail.is_hidden,
          is_disabled: this.state.topicDetail.is_disabled
        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            message.success('Topic Updated Successfully')
            this.props.updateState(item.response_objects)
            this.props.toggle()
            this.setState({ loading: false })
          } else {
            message.error(item.message)
          }
        })
        .catch(err => {
          message.error(err)
        })
    }
  }

  handleOptionChange (event) {
    const topicDetail = this.state.topicDetail
    topicDetail[event.target.name] = event.target.value
    this.setState({ topicDetail })
  }

  handleOptionHiddenChange (event) {
    const topicDetail = this.state.topicDetail
    topicDetail[event.target.name] = event.target.value
    this.setState({ topicDetail })
  }

  handleDropdownChange (e) {
    const topicDetail = this.state.topicDetail
    topicDetail[e.target.name] = e.target.value
    this.setState({ topicDetail })
  }

  componentDidMount () {
    if (this.props.item) {
      const topicDetail = this.state.topicDetail
      topicDetail.id = this.props.item.id
      topicDetail.category_id = this.props.item.category_id
      topicDetail.topic = this.props.item.topic
      topicDetail.is_hidden = this.props.item.is_hidden
      topicDetail.is_disabled = this.props.item.is_disabled
      this.setState({ topicDetail })
    }
    this.getCategory()
  }

  render () {
    return (
      this.state.loading
        ? <div id="loadingContent">
          <img align="absmiddle" alt="" src="../images/loading.gif" />
        </div>
        : <Form onSubmit={this.props.item ? this.submitFormEdit : this.submitFormAdd} className="form-horizontal">
          <FormGroup>
            <Label for="category_id" className="control-label col-sm-2">Category</Label>
            <div className="col-sm-10">
              <select name="category_id" className="form-control" onChange={this.handleDropdownChange} value={this.state.topicDetail.category_id === null ? '' : this.state.topicDetail.category_id}>
                <option value=''>Select Category</option>
                {this.state.categoryList}
              </select>
              <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.category_id}</span>
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="topic" className="control-label col-sm-2">Topic</Label>
            <div className="col-sm-10"><Input type="text" name="topic" id="topic" onChange={this.onChange} value={this.state.topicDetail.topic === null ? '' : this.state.topicDetail.topic} />
              <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.topic}</span>

            </div>
          </FormGroup>
          <FormGroup>
            <Label for="Active" className="control-label col-sm-4">Hidden</Label>
            <div className="col-sm-10">
              <input type="radio" value="true" name="is_hidden" id="activeNo" checked={this.state.topicDetail.is_hidden === true || this.state.topicDetail.is_hidden === 'true'} onChange={this.handleOptionHiddenChange} /> Yes
               &nbsp; <input type="radio" value="false" name="is_hidden" id="activeYes" checked={this.state.topicDetail.is_hidden === false || this.state.topicDetail.is_hidden === 'false'} onChange={this.handleOptionHiddenChange} /> No

              <div><span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.is_hidden}</span></div>
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="Active" className="control-label col-sm-2">Active</Label>
            <div className="col-sm-10">
              <input type="radio" value="false" name="is_disabled" id="activeYes" checked={this.state.topicDetail.is_disabled === false || this.state.topicDetail.is_disabled === 'false'} onChange={this.handleOptionChange} /> Yes
                &nbsp;<input type="radio" value="true" name="is_disabled" id="activeNo" checked={this.state.topicDetail.is_disabled === true || this.state.topicDetail.is_disabled === 'true'} onChange={this.handleOptionChange} /> No
              <div><span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.is_disabled}</span></div>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-sm-1"></div>
            <div className="col-sm-10"><Button className="btn-success">Submit</Button></div>
          </FormGroup>
        </Form>
    )
  }
}

export default AddEditForm
