/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-return-assign */

import React, { Component } from 'react'
import { Table } from 'reactstrap'
import ModalForm from '../Modals/User'
import { Link } from 'react-router-dom'

class DataTable extends Component {
  deleteItem = id => {
    const confirmDelete = window.confirm('Are you sure want to delete?')
    if (confirmDelete) {
      fetch(process.env.REACT_APP_API_ENDPOINT + '/crud/deleteUser', {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id
        })
      })
        .then(response => response.json())
        .then(item => {
          this.props.deleteItemFromState(id)
        })
        .catch(err => console.log(err))
    }
  }

  render () {
    const imageConstant = 'https://gathering-images-s3.twine.nyc/profile/100x100/'
    const imageType = '.jpg'
    const brokenImageConstant = 'https://gathering-images-s3.twine.nyc/profile/'
    if (this.props.items.length === 0) {
      return (
        <Table responsive hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Image</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Approved</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="8" align="center">No Records Found</td>
            </tr>
          </tbody>
        </Table>
      )
    } else {
      const items = this.props.items.map(item => {
        var imagetag
        if ((item.image_unique_id) != null) {
          imagetag = <img
            style={{ width: 50, height: 50 }}
            onError={(e) => e.target.src = brokenImageConstant + item.image_unique_id + imageType}
            src={imageConstant + item.image_unique_id + imageType}
            alt=""
          />
        }
        return (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.first_name}</td>
            <td>{item.last_name}</td>
            <td>{imagetag}</td>
            <td>{item.phone_number_code} {item.phone_number}</td>
            <td>{item.email}</td>
            <td>{(item.is_approved === 'true' || item.is_approved === true) ? 'Yes' : 'No'}</td>
            <td>{(item.is_disabled === 'true' || item.is_disabled === true) ? 'No' : 'Yes'}</td>
            <td>
              <div style={{ width: '150px' }}>
                <ModalForm buttonLabel="Edit" item={item} updateState={this.props.updateState} />
                {' '}
              </div>
              <button type="submit" className="btn btn-primary" ><Link to={`/dashboard/userView/${item.id}`} style={{ color: 'white' }}>View</Link>
              </button>
            </td>
          </tr>
        )
      })
      return (
        <Table responsive hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Image</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Approved</th>
              <th>Active</th>
              <th style={{ paddingLeft: '40px' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {items}
          </tbody>
        </Table>
      )
    }
  }
}

export default DataTable
