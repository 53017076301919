/* eslint-disable no-unused-vars */

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const allowSentry = process.env.REACT_APP_ALLOW_SENTRY

if (allowSentry === 'true') {
  Sentry.init({
    ignoreErrors: [
      'Client is unable to apply a remote media description'
    ],
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [
      new Integrations.BrowserTracing()
    ],
    tracesSampleRate: 1.0
  })
  Sentry.configureScope(function (scope) {
    scope.setTag('environment', process.env.REACT_APP_SENTRY_ENV)
  })
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)

serviceWorker.unregister()
