/* eslint-disable no-unused-vars */

import React, { Component } from 'react'
import { Table } from 'reactstrap'
import moment from 'moment'

class DataTable extends Component {
  render () {
    if (this.props.items.length === 0) {
      return (
        <Table responsive hover>
          <thead>
            <tr>
              <th>Point Type </th>
              <th>Description</th>
              <th>Points</th>
              <th>Credited On</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="8" align="center">No Records Found</td>
            </tr>
          </tbody>
        </Table>
      )
    }
    const items = this.props.items.map((item) => (
      <tr key={item.id}>
        <td>{item.name}</td>
        <td>{item.description }</td>
        <td>{item.points}</td>
        <td>{ moment(item.created_at).format('MM/DD/YYYY HH:mm:ss a') }</td>
      </tr>
    ))
    return (
      <Table responsive hover>
        <thead>
          <tr>
            <th>Point Type </th>
            <th>Description</th>
            <th>Points</th>
            <th>Credited On</th>
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
      </Table>
    )
  }
}

export default DataTable
