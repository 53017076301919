/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import React from 'react'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { Upload, message } from 'antd'
import { Multiselect } from 'multiselect-react-dropdown'
import Select from 'react-select'
import { CONSTANTS } from '../constants'
import { UploadOutlined } from '@ant-design/icons'

let duration = 0

class AddEditForm extends React.Component {
  constructor (props) {
    super(props)
    this.handleOptionActiveChange = this.handleOptionActiveChange.bind(this)
    this.state = {
      spaceDetail: {
        id: 0,
        name: '',
        description: '',
        is_disabled: false,
        video: '',
        intro_video_unique_id: ''
      },
      searchName: '',
      pageSize: 10,
      errorMessage: '',
      image_unique_id: '',
      image: '',
      errorMsg: {},
      loading: false,
      organization_id: [],
      selectedOrganisationIds: [],
      initialOrganization: [],
      orgRequestIds: [],
      organizationList: [],
      admin_id: [],
      selectedAdminIds: [],
      initialAdmins: [],
      adminRequestIds: [],
      userList: [],
      searchEmailText: '',
      searchOrgText: ''
    }
  }

  onChange = e => {
    const spaceDetail = this.state.spaceDetail
    spaceDetail[e.target.name] = e.target.value
    this.setState({ spaceDetail })
  }

  onSelectAdmins = e => {
    const adminSelected = []
    if (e) {
      e.forEach((value) => { adminSelected.push(value.id) })
      this.setState({ admin_id: e, selectedAdminIds: adminSelected })
    } else {
      this.setState({ admin_id: '', selectedAdminIds: adminSelected })
    }
  }

  handleInputChange (searchText) {
    this.setState({
      searchEmailText: searchText
    })
    this.getAllActiveUser(searchText)
  }

  handleInputOrgChange (searchOrg) {
    this.setState({
      searchOrgText: searchOrg
    })
    this.getOrganization(searchOrg)
  }

  onSelectOrg = e => {
    const orgSelected = []
    if (e) {
      e.forEach((value) => { orgSelected.push(value.id) })
      this.setState({ organization_id: e, selectedOrganisationIds: orgSelected })
    } else {
      this.setState({ organization_id: '', selectedOrganisationIds: orgSelected })
    }
  }

  validateSpaceForm () {
    const spaceDetail = this.state.spaceDetail
    const error = {}
    let formValid = true

    if (spaceDetail.name === '' || spaceDetail.name === null) {
      error.name = 'Please Enter the space name'
      formValid = false
    }
    if (spaceDetail.is_disabled === '' || spaceDetail.is_disabled === null) {
      error.is_disabled = 'Please Choose the Active'
      formValid = false
    }

    this.setState({ errorMsg: error })
    return formValid
  }

  submitFormAdd = e => {
    e.preventDefault()
    if (!this.validateSpaceForm()) {
    } else {
      this.setState({ loading: true })
      fetch(process.env.REACT_APP_API_ENDPOINT + '/space/add', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          name: escape(this.state.spaceDetail.name),
          image_unique_id: this.state.spaceImage,
          description: escape(this.state.spaceDetail.description),
          is_disabled: this.state.spaceDetail.is_disabled
        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            message.success('Space Added Successfully')
            this.setState({
              spaceId: item.response_objects.id
            })
            if (this.state.videoFile) {
              this.uploadVideo(duration, item.response_objects.id)
            } else {
              this.props.addItemToState(item.response_objects)
              this.props.toggle()
              this.setState({ loading: false })
            }
          } else {
            message.error(item.message)
            this.setState({ loading: false })
          }
        })
        .catch(err => {
        })
    }
  }

  submitFormEdit = e => {
    e.preventDefault()
    if (!this.validateSpaceForm()) {
    } else {
      this.setState({ loading: true })
      fetch(process.env.REACT_APP_API_ENDPOINT + '/space/add', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          id: this.state.spaceDetail.id,
          name: escape(this.state.spaceDetail.name),
          image_unique_id: this.state.spaceImage,
          description: escape(this.state.spaceDetail.description),
          is_disabled: this.state.spaceDetail.is_disabled
        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            message.success('Space Updated Successfully')
            if (this.state.videoFile) {
              this.uploadVideo(duration, item.response_objects.id)
            } else {
              this.props.updateState(item.response_objects)
              this.props.toggle()
              this.setState({ loading: false })
            }
            this.addSpaceOrganization()
            this.addSpaceAdmin()
          } else {
            message.error(item.message)
            this.setState({ loading: false })
          }
        })
        .catch(err => {
          message.error(err)
        })
    }
  }

  handleOptionActiveChange (event) {
    const spaceDetail = this.state.spaceDetail
    spaceDetail[event.target.name] = event.target.value
    this.setState({ spaceDetail })
  }

  fileOnChange = (info) => {
    if (info.file.status !== 'uploading') {
    }
    if (info.file.status === 'done') {
      document.getElementById('image_unique_id').value = info.file.response.image_unique_id
      this.setState({
        spaceImage: info.file.response.image_unique_id
      })
      message.success(`${info.file.name} file uploaded successfully`)
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  beforeUpload (file) {
    const isJpg = file.type === 'image/jpeg' || file.type === 'image/jpg'
    if (!isJpg) {
      message.error('You can only upload JPG file!')
    }
    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      message.error('Image must smaller than 5MB!')
    }
    return isJpg && isLt5M
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const file = event.target.files[0]
    this.setState({ videoFile: file })
    if (file !== null && file !== '' && file !== undefined) {
      if (file.size > 80e6) {
        this.setState({ errorMessage: 'Please upload a file smaller than 80 MB' })
        return false
      }
      const types = /(\.|\/)(mp4)$/i
      if (types.test(file.type) || types.test(file.name)) {
        let obUrl
        if (file.name.match(/\.(avi|mp3|mp4|mpeg|ogg)$/i)) {
          obUrl = URL.createObjectURL(file)
          document.getElementById('audio').setAttribute('src', obUrl)
        }
        document.getElementById('audio').addEventListener('canplaythrough', (e) => {
          duration = Math.round(e.currentTarget.duration)
          this.setState({
            duration
          })
        })
      } else {
        this.setState({ errorMessage: 'Please Choose MP4 Video Only', videoFile: '' })
        return false
      }
    } else {
      this.setState({ errorMessage: 'Please Choose MP4 Intro Video' })
      return false
    }
  };

  uploadVideo = (duration, spaceId) => {
    const formData = new FormData()
    formData.append('video', this.state.videoFile)
    formData.append('intro_video_duration', duration)
    this.setState({ loading: true })
    fetch(process.env.REACT_APP_API_ENDPOINT + `/space/${spaceId}/videos`, {
      headers: {
        Authorization: window.localStorage.getItem('token')
      },
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          message.success('Space Video Upload Successfully')
          this.props.toggle()
          this.setState({ loading: false, videoFile: '' })
          window.location.reload()
          duration = 0
        } else {
          this.setState({
            loading: false
          })
          message.error(item.message)
          this.props.toggle()
        }
      })
      .catch(err => {
        message.error(err)
        this.setState({
          loading: false
        })
      })
  }

  handleDelete = (event) => {
    event.preventDefault()
    fetch(process.env.REACT_APP_API_ENDPOINT + '/space/removeVideo', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      },
      body: JSON.stringify({
        video_id: this.state.spaceDetail.video_id,
        is_disabled: true
      })
    })
      .then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          message.success('Space Video Removed Successfully')
          this.props.toggle()
          this.props.updateState(item.response_objects)
        } else {
          message.error(item.message)
          this.props.toggle()
        }
      })
      .catch(err => {
        message.error(err)
      })
  }

  // Get Active Orgs
  getOrganization (searchText) {
    if (searchText && searchText.length >= CONSTANTS.searchTextLength) {
      fetch(process.env.REACT_APP_API_ENDPOINT + `/orgs/getAllActive?searchText=${searchText}&pageSize=${CONSTANTS.pageLimit}`, {
        method: 'get',
        headers: {
          Authorization: window.localStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(items => {
          if (Array.isArray(items.response_objects)) {
            const options = items.response_objects.map((data) => {
              return { id: data.id, value: data.name, label: data.name }
            }
            )
            this.setState({
              organizationList: options
            })
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
    } else if (!searchText) {
      fetch(process.env.REACT_APP_API_ENDPOINT + `/orgs/getAllActive?searchText=${searchText}&pageSize=${CONSTANTS.pageLimit}`, {
        method: 'get',
        headers: {
          Authorization: window.localStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(items => {
          if (Array.isArray(items.response_objects)) {
            const options = items.response_objects.map((data) => {
              return { id: data.id, value: data.name, label: data.name }
            }
            )
            this.setState({
              organizationList: options
            })
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
    }
  }

  // Get Active Orgs By SpaceID
  getOrganizationBySpaceId = (id) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + `/space/getOrganizationBySpaceId/${id}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          const orgData = []
          item.response_objects.map((value) => {
            const options = { id: value.organization_id, value: value.name, label: value.name }
            this.state.selectedOrganisationIds.push(value.organization_id)
            this.state.orgRequestIds.push(value.organization_id)
            orgData.push(options)
          })
          this.setState({
            initialOrganization: item.response_objects,
            organization_id: orgData
          })
        }
      }).catch(err => console.log(err))
  }

  // Add or Remove Orgs for given SpaceID
  addSpaceOrganization = () => {
    const organization_id = this.state.organization_id
    const organizationRequest = []
    if (this.state.initialOrganization && this.state.initialOrganization.length > 0) {
      this.state.initialOrganization.map((value) => {
        this.state.selectedOrganisationIds.push({ id: value.id, organization_id: value.organization_id, is_disabled: true })
      })
      this.state.selectedOrganisationIds.map((value) => {
        if (value.is_disabled) {
          organizationRequest.push(value)
        } else {
          if (this.state.orgRequestIds.indexOf(value) >>> -1 !== true) {
            organizationRequest.push({ id: '', organization_id: value, is_disabled: false })
          }
        }
      })
    } else {
      organization_id.map((value) => {
        organizationRequest.push({ id: '', organization_id: value.id, is_disabled: false })
      })
    }
    if (organizationRequest && organizationRequest.length > 0) {
      fetch(process.env.REACT_APP_API_ENDPOINT + '/space/addSpaceOrganization', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          organizationsIds: organizationRequest,
          space_id: this.state.id
        })
      }).then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {

          }
        }).catch(err => console.log(err))
    }
  }

  // Get Active Users
  getAllActiveUser (searchText) {
    if (searchText && searchText.length >= 3) {
      fetch(process.env.REACT_APP_API_ENDPOINT + `/users/getAllActiveUser?searchText=${searchText}&pageSize=${CONSTANTS.pageLimit}`, {
        method: 'get',
        headers: {
          Authorization: window.localStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(items => {
          if (Array.isArray(items.response_objects)) {
            const options = items.response_objects.map((data) => {
              return { id: data.id, value: data.email, label: data.email }
            })
            this.setState({
              userList: options
            })
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
    } else if (!searchText) {
      fetch(process.env.REACT_APP_API_ENDPOINT + `/users/getAllActiveUser?searchText=${searchText}&pageSize=${CONSTANTS.pageLimit}`, {
        method: 'get',
        headers: {
          Authorization: window.localStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(items => {
          if (Array.isArray(items.response_objects)) {
            const options = items.response_objects.map((data) => {
              return { id: data.id, value: data.email, label: data.email }
            }
            )
            this.setState({
              userList: options
            })
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
    }
  }

  // Get Active Orgs By SpaceID
  getAdminBySpaceId = (id) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + `/space/getAdminBySpaceId/${id}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          const adminData = []
          item.response_objects.map((value) => {
            const options = { id: value.user_id, value: value.email, label: value.email }
            this.state.selectedAdminIds.push(value.user_id)
            this.state.adminRequestIds.push(value.user_id)
            adminData.push(options)
          })
          this.setState({
            initialAdmins: item.response_objects,
            admin_id: adminData
          })
        }
      }).catch(err => console.log(err))
  }

  // Add or Remove Admins for given SpaceID
  addSpaceAdmin = () => {
    const admin_id = this.state.admin_id
    const adminRequest = []
    if (this.state.initialAdmins && this.state.initialAdmins.length > 0) {
      this.state.initialAdmins.map((value) => {
        this.state.selectedAdminIds.push({ id: value.id, user_id: value.user_id, is_disabled: true })
      })
      this.state.selectedAdminIds.map((value) => {
        if (value.is_disabled) {
          adminRequest.push(value)
        } else {
          if (this.state.adminRequestIds.indexOf(value) >>> -1 !== true) {
            adminRequest.push({ id: '', user_id: value, is_disabled: false })
          }
        }
      })
    } else {
      admin_id.map((value) => {
        adminRequest.push({ id: '', user_id: value.id, is_disabled: false })
      })
    }
    if (adminRequest && adminRequest.length > 0) {
      fetch(process.env.REACT_APP_API_ENDPOINT + '/space/addSpaceAdmin', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          userIds: adminRequest,
          space_id: this.state.id
        })
      }).then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {

          }
        }).catch(err => console.log(err))
    }
  }

  componentDidMount () {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const id = this.props.item.id
      this.getAllActiveUser(this.state.searchEmailText)
      this.getAdminBySpaceId(id)
      this.getOrganization(this.state.searchOrgText)
      this.getOrganizationBySpaceId(id)
      const spaceDetail = this.state.spaceDetail
      spaceDetail.id = this.props.item.id
      spaceDetail.name = this.props.item.name
      spaceDetail.description = this.props.item.description
      spaceDetail.is_disabled = this.props.item.is_disabled
      spaceDetail.intro_video_unique_id = this.props.item.intro_video_unique_id
      spaceDetail.video_id = this.props.item.video_id

      const image = this.props.item.image
      const image_unique_id = this.props.item.image_unique_id

      this.setState({ id: id, spaceDetail, image, image_unique_id, spaceImage: image_unique_id })
    }
  }

  render () {
    const imageConstant = 'https://space-images-s3.twine.nyc/space/'
    const imageType = '.jpg'
    const props = {
      name: 'image',
      action: process.env.REACT_APP_API_ENDPOINT + '/space/upload',
      method: 'PUT',
      accept: '.jpg, .jpeg',
      headers: {
        Authorization: window.localStorage.getItem('token')
      },
      multiple: false,
      showUploadList: false
    }
    return (
      this.state.loading
        ? <div id="loadingContent">
          <img align="absmiddle" alt="" src="../images/loading.gif" />
        </div>
        : <Container>
          <Form className="form-horizontal">
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label for="name" className="control-label col-sm-4">Name</Label>
                  <div className="col-sm-12"><Input type="text" name="name" id="name" onChange={this.onChange} value={this.state.spaceDetail.name === null ? '' : this.state.spaceDetail.name} />
                    <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.name}</span>
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="description" className="control-label col-sm-4">Description</Label>
                  <div className="col-sm-12"><textarea className="form-control" name="description" id="description" onChange={this.onChange} value={this.state.spaceDetail.description === null ? '' : this.state.spaceDetail.description} />
                    <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.description}</span>
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="organization_id" className="control-label col-sm-2">Organization</Label>
                  <div className="col-sm-12">
                    <Select
                      options={this.state.organizationList && this.state.organizationList.length ? this.state.organizationList : []}
                      value={this.state.organization_id}
                      onChange={this.onSelectOrg}
                      isMulti
                      isSearchable={true}
                      onInputChange={(inputText) => this.handleInputOrgChange(inputText)}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="admin_id" className="control-label col-sm-2">Admins</Label>
                  <div className="col-sm-12">
                    <Select
                      options={this.state.userList && this.state.userList.length ? this.state.userList : []}
                      value={this.state.admin_id}
                      onChange={this.onSelectAdmins}
                      isMulti
                      isSearchable={true}
                      onInputChange={(inputText) => this.handleInputChange(inputText)}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="image" className="control-label col-sm-4">Image</Label>
                  <div className="col-sm-12">
                    {this.state.spaceImage ? <div><img
                      style={{ width: 75, height: 75 }}
                      src={imageConstant + this.state.spaceImage + imageType}
                      alt=""
                    /> <br /> <br /> </div> : ''}
                    <Upload {...props}
                      beforeUpload={this.beforeUpload}
                      onChange={this.fileOnChange}>
                      <Button type="button">
                        <UploadOutlined /> Click to Upload
                      </Button>
                    </Upload>
                    <Input type="hidden" name="image_unique_id" id="image_unique_id" onChange={this.onChange} value={this.state.image_unique_id === null ? '' : this.state.image_unique_id} />
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="image" className="control-label col-sm-4">Intro Video</Label>
                  {!this.state.spaceDetail.video_id ? <div className="col-sm-12">
                    <input type="file" name="file" id='fup' onChange={this.handleSubmit} />
                    <input type='hidden' name='f_du' id='f_du' size='5' />
                    <audio id='audio'></audio>
                  </div>
                    : <div className="col-sm-12">
                      <Button
                        color="primary"
                        disabled
                      >
                        <i className="fa fa-play" style={{ fontSize: '18px' }}></i>
                      </Button>
                      <i className="fa fa-trash" style={{ fontSize: '18px', marginLeft: '3%' }} onClick={this.handleDelete}></i>
                    </div>}

                  {!this.state.errorMessage ? '' : <div style={{ marginTop: '2%', marginBottom: '-2%' }}><span style={{ color: '#d31820', fontSize: '14', textAlign: 'center', marginLeft: '4%' }}>{this.state.errorMessage}</span></div>}
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="Active" className="control-label col-sm-4">Active</Label>
                  <div className="col-sm-12">
                    <input type="radio" value="false" name="is_disabled" id="activeYes" checked={this.state.spaceDetail.is_disabled === false || this.state.spaceDetail.is_disabled === 'false'} onChange={this.handleOptionActiveChange} /> Yes
                &nbsp;<input type="radio" value="true" name="is_disabled" id="activeNo" checked={this.state.spaceDetail.is_disabled === true || this.state.spaceDetail.is_disabled === 'true'} onChange={this.handleOptionActiveChange} /> No
                    <div><span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.is_disabled}</span></div>
                  </div>
                </FormGroup>
              </Col>

              {/*
              {this.props.item ? <>
                {this.props.item.intro_video_unique_id === null
                  ? <Col xs="6">

                    <FormGroup>
                      <Label for="image" className="control-label col-sm-4">Intro Video</Label>
                      <div className="col-sm-12">
                        <input type="file" name="file" id='fup' onChange={this.handleSubmit} />
                        <input type='hidden' name='f_du' id='f_du' size='5' />
                        <audio id='audio'></audio>

                      </div>
                      {this.state.errorMessage === null || this.state.errorMessage === '' ? '' : <div style={{ marginTop: '2%', marginBottom: '-2%' }}><span style={{ color: '#d31820', fontSize: '14', textAlign: 'center', marginLeft: '4%' }}>{this.state.errorMessage}</span></div>}
                    </FormGroup>
                  </Col>

                  : <Col xs="6">

                    <FormGroup>
                      <Label for="image" className="control-label col-sm-4">Intro Video</Label>
                      <div className="col-sm-12">

                        <Button
                          color="primary"
                          disabled
                        >
                          <i className="fa fa-play" style={{ fontSize: '18px' }}></i>

                        </Button>
                        <i className="fa fa-trash" style={{ fontSize: '18px', marginLeft: '3%' }} onClick={this.handleDelete}></i>

                      </div>
                    </FormGroup>
                  </Col>
                }
              </> : null} */}

            </Row>

            <FormGroup>
              <div className="col-sm-2"></div>
              <div className="col-sm-10"><Button className="btn-success" onClick={this.props.item ? this.submitFormEdit : this.submitFormAdd}>Submit</Button></div>
            </FormGroup>
          </Form>
        </Container>
    )
  }
}

export default AddEditForm
