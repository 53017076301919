/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import React from 'react'
import { Container, Row, Col, Form, Input } from 'reactstrap'
import ModalForm from './Components/Modals/Organization'
import DataTable from './Components/Tables/OrganizationDataTable'
import ReactPaginate from 'react-paginate'

class Organization extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      items: [],
      searchName: '',
      pageSize: 10,
      pageNo: 1,
      pageCount: 1,
      loading: false
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleClick = (e) => {
    if (e) { e.preventDefault() }
    this.getItems(1)
  }

  getItems (pageNumber) {
    this.setState({ loading: true })

    fetch(process.env.REACT_APP_API_ENDPOINT + '/orgs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      },
      body: JSON.stringify({
        searchName: escape(this.state.searchName),
        pageSize: this.state.pageSize,
        pageNo: pageNumber
      })
    }).then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          var items = item.response_objects
          var count = item.totalCount
          this.setState({ items, pageCount: Math.ceil(count / this.state.pageSize), loading: false })
        } else {
          // this.setState({ error_message: item.message});
        }
      })
      .catch(err => console.log(err))
  }

  addItemToState = (item) => {
    this.setState(prevState => ({
      items: [...prevState.items, item]
    }))
  }

  updateState = (item) => {
    this.setState({ searchName: '' })
    this.getItems(this.state.pageNo)
  }

  deleteItemFromState = (id) => {
    const updatedItems = this.state.items.filter(item => item.id !== id)
    this.setState({ items: updatedItems })
  }

  componentWillMount () {
    this.getItems(this.state.pageNo)
  }

  handlePageClick (data) {
    let selected = data.selected
    selected = selected + 1
    this.setState({ pageNo: selected })
    this.getItems(selected)
  }

  render () {
    return (
      this.state.loading
        ? <div id="loadingContent">
          <img align="absmiddle" alt="" src="../images/loading.gif" />
        </div>
        : <Container className="Organization">
          <Row>
            <Col>
              <ModalForm buttonLabel="Add  Organization" addItemToState={this.addItemToState} />
            </Col>
            <Form onSubmit={this.handleClick} className="form-horizontal">

              <Row>

                <Col className="" style={{ width: '300px', marginLeft: '-50px' }}>
                  <Input type="text" name="searchName" placeholder="Search..." id="searchName" onChange={this.onChange} value={this.state.searchName === null ? '' : this.state.searchName} />
                </Col>
                <Col xs="2" style={{ marginLeft: '-25px', paddingTop: '1px' }}>
                  <button type="submit"><i className="fa fa-search"></i></button>
                </Col>

              </Row>
            </Form>

          </Row>
          <Row>
            <Col>
              <h1 style={{ margin: '20px 0' }}>Organization List</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable items={this.state.items} updateState={this.updateState} deleteItemFromState={this.deleteItemFromState} />
            </Col>
          </Row>

          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break'}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={5}
            pageRangeDisplayed={5}
            forcePage={this.state.pageNo - 1}
            onPageChange={this.handlePageClick.bind(this)}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />

        </Container>
    )
  }
}

export default Organization
