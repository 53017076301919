/* eslint-disable no-unused-vars */
/* eslint-disable */

import React from 'react'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { message } from 'antd'
let duration = 0
class AddEditForm extends React.Component {
  constructor (props) {
    super(props)
    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.state = {
      organizationDetail: {
        id: 0,
        map_id: '',
        name: '',
        is_disabled: '',
        feature_id: 1,
        organization_id: '',
        feature_disabled: '',
        video: '',
        intro_video_unique_id: '',
        duration: ''
      },
      loading: false,
      selectedFile: null,
      errorMsg: {},
      errorMessage: ''
    }
  }

  validateOrganizationForm () {
    const organizationDetail = this.state.organizationDetail
    const error = {}
    let formValid = true
    if (organizationDetail.name === '' || organizationDetail.name === null) {
      error.name = 'Please enter the Name'
      formValid = false
    }
    if (organizationDetail.is_disabled === '' || organizationDetail.is_disabled === null) {
      error.is_disabled = 'Please Choose the Active'
      formValid = false
    }
    this.setState({ errorMsg: error })
    return formValid
  }

  onChange = e => {
    const organizationDetail = this.state.organizationDetail
    organizationDetail[e.target.name] = e.target.value
    this.setState({ organizationDetail })
  }

  submitFormAdd = e => {
    e.preventDefault()
    if (!this.validateOrganizationForm()) {
    } else {
      this.setState({ loading: true })
      const organizationDetail = this.state.organizationDetail
      fetch(process.env.REACT_APP_API_ENDPOINT + '/orgs/add', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          name: escape(this.state.organizationDetail.name),
          is_disabled: this.state.organizationDetail.is_disabled
        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            message.success('Organization Added Successfully')
            if (organizationDetail.feature_disabled === 'false' || (organizationDetail.feature_disabled === false && organizationDetail.map_id === null)) {
              this.addFeature(item.response_objects.id)
              this.props.toggle()
              this.props.addItemToState(item.response_objects)
            } else {
              this.props.toggle()
              this.props.addItemToState(item.response_objects)
              window.location.reload()
            }
            this.setState({ loading: false })
          } else {
            message.error(item.message)
            this.props.toggle()
          }
        })
        .catch(err => {
          message.error(err)
        })
    }
  }

  submitFormEdit = e => {
    e.preventDefault()
    if (!this.validateOrganizationForm()) {
    } else {
      this.setState({ loading: true })
      const organizationDetail = this.state.organizationDetail
      fetch(process.env.REACT_APP_API_ENDPOINT + '/orgs/add', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          id: this.state.organizationDetail.id,
          name: escape(this.state.organizationDetail.name),
          is_disabled: this.state.organizationDetail.is_disabled
        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            message.success('Organization Updated Successfully')
            if (organizationDetail.map_id === null && (organizationDetail.feature_disabled === 'false' || organizationDetail.feature_disabled === false)) {
              this.addFeatures(item.response_objects.id)
            } else if ((organizationDetail.feature_disabled === 'false' || organizationDetail.feature_disabled === false) && organizationDetail.map_id !== null) {
              this.addFeaturesEdit(item.response_objects.id)
            } else if ((organizationDetail.feature_disabled === 'true' || organizationDetail.feature_disabled === true) && organizationDetail.map_id !== null) {
              this.addFeaturesEdit(item.response_objects.id)
            }
            this.props.updateState(item.response_objects)
            this.setState({ loading: false })
          } else {
            message.error(item.message)
            this.props.toggle()
          }
        })
        .catch(err => {
          message.error(err)
        })
    }
  }

  addFeatures = (id) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + '/orgs/addFeature', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      },
      body: JSON.stringify({
        feature_id: this.state.organizationDetail.feature_id,
        organization_id: id,
        is_disabled: this.state.organizationDetail.feature_disabled
      })
    })
      .then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          this.props.toggle()
          this.props.updateState(item.response_objects)
        } else {
          message.error(item.message)
          this.props.toggle()
        }
      })
      .catch(err => {
        message.error(err)
      })
  }

  addFeaturesEdit = (id) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + '/orgs/addFeature', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      },
      body: JSON.stringify({
        map_id: this.state.organizationDetail.map_id,
        is_disabled: this.state.organizationDetail.feature_disabled
      })
    })
      .then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          this.props.toggle()
          this.props.updateState(item.response_objects)
        } else {
          message.error(item.message)
          this.props.toggle()
        }
      })
      .catch(err => {
        message.error(err)
      })
  }

  addFeature = (id) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + '/orgs/addFeature', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      },
      body: JSON.stringify({
        feature_id: this.state.organizationDetail.feature_id,
        organization_id: id,
        is_disabled: this.state.organizationDetail.feature_disabled
      })
    })
      .then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          window.location.reload()
        } else {
          message.error(item.message)
          this.props.toggle()
        }
      })
      .catch(err => {
        message.error(err)
      })
  }

  onHandleChange = (event) => {
    const organizationDetail = this.state.organizationDetail
    organizationDetail[event.target.name] = event.target.checked
    if (event.target.checked) {
      organizationDetail[event.target.name] = 'false'
    } else {
      organizationDetail[event.target.name] = 'true'
    }
    this.setState({ organizationDetail })
  }

  handleOptionChange (event) {
    const organizationDetail = this.state.organizationDetail
    organizationDetail[event.target.name] = event.target.value
    this.setState({ organizationDetail })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const file = event.target.files[0]
    this.setState({ videoFile: file })
    if (file !== null && file !== '' && file !== undefined) {
      if (file.size > 100e6) {
        this.setState({ errorMessage: 'Please upload a file smaller than 100 MB' })
        return false
      }
      const types = /(\.|\/)(mp4)$/i
      if (types.test(file.type) || types.test(file.name)) {
        let obUrl
        if (file.name.match(/\.(avi|mp3|mp4|mpeg|ogg)$/i)) {
          obUrl = URL.createObjectURL(file)
          document.getElementById('audio').setAttribute('src', obUrl)
        }
        document.getElementById('audio').addEventListener('canplaythrough', (e) => {
          duration = Math.round(e.currentTarget.duration)
          this.uploadVideo(duration)
        })
      } else {
        this.setState({ errorMessage: 'Please Choose MP4 Video Only' })
        return false
      }
    } else {
      this.setState({ errorMessage: 'Please Choose MP4 Intro Video' })
      return false
    }
  };

  uploadVideo = (duration) => {
    const formData = new FormData()
    formData.append('video', this.state.videoFile)
    formData.append('intro_video_duration', duration)
    this.setState({ loading: true })
    fetch(process.env.REACT_APP_API_ENDPOINT + `/orgs/${this.props.item.id}/videos`, {
      headers: {
        Authorization: window.localStorage.getItem('token')
      },
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          message.success('Organization Video Upload Successfully')
          this.props.toggle()
          this.props.updateState(item.response_objects)
          this.setState({ loading: false, videoFile: '' })
          duration = 0
        } else {
          message.error(item.message)
          this.props.toggle()
        }
      })
      .catch(err => {
        message.error(err)
      })
  }

  handleDelete = (event) => {
    event.preventDefault()
    fetch(process.env.REACT_APP_API_ENDPOINT + '/orgs/removeVideo', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      },
      body: JSON.stringify({
        video_id: this.state.organizationDetail.video_id,
        is_disabled: true
      })
    })
      .then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          message.success('Organization Video Removed Successfully')
          this.props.toggle()
          this.props.updateState(item.response_objects)
        } else {
          message.error(item.message)
          this.props.toggle()
        }
      })
      .catch(err => {
        message.error(err)
      })
  }

  componentDidMount () {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const organizationDetail = this.state.organizationDetail
      organizationDetail.id = this.props.item.id
      organizationDetail.name = this.props.item.name
      organizationDetail.is_disabled = this.props.item.is_disabled
      organizationDetail.feature_disabled = this.props.item.feature_disabled
      organizationDetail.map_id = this.props.item.map_id
      organizationDetail.intro_video_unique_id = this.props.item.intro_video_unique_id
      organizationDetail.video_id = this.props.item.video_id
      this.setState({ organizationDetail })
    }
  }

  render () {
    return (
      this.state.loading
        ? <div id="loadingContent">
          <img align="absmiddle" alt="" src="../images/loading.gif" />
        </div>
        : <Form onSubmit={this.props.item ? this.submitFormEdit : this.submitFormAdd} className="form-horizontal">

          <FormGroup>
            <Label for="category" className="control-label col-sm-2">Name</Label>
            <div className="col-sm-10">
              <Input type="text" name="name" id="name" onChange={this.onChange} value={this.state.organizationDetail.name} />
              <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.name}</span>
            </div>
          </FormGroup>

          <FormGroup>
            <Label for="Active" className="control-label col-sm-2">Active</Label>
            <div className="col-sm-10">
              <input type="radio" value="false" name="is_disabled" id="activeYes" checked={this.state.organizationDetail.is_disabled === false || this.state.organizationDetail.is_disabled === 'false'} onChange={this.handleOptionChange} /> Yes
                &nbsp;<input type="radio" value="true" name="is_disabled" id="activeNo" checked={this.state.organizationDetail.is_disabled === true || this.state.organizationDetail.is_disabled === 'true'} onChange={this.handleOptionChange} /> No
              <div><span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.is_disabled}</span></div>
            </div>
          </FormGroup>

          {this.props.item ? <>
            {this.props.item.intro_video_unique_id === null
              ? <FormGroup>
                <Label for="image" className="control-label col-sm-4">Intro Video</Label>
                <div className="col-sm-12">
                  <input type="file" name="file" id='fup' onChange={this.handleSubmit} />
                  <input type='hidden' name='f_du' id='f_du' size='5' />
                  <audio id='audio'></audio>

                </div>
                {this.state.errorMessage === null || this.state.errorMessage === '' ? '' : <div style={{ marginTop: '2%', marginBottom: '-2%' }}><span style={{ color: '#d31820', fontSize: '14', textAlign: 'center', marginLeft: '3%' }}>{this.state.errorMessage}</span></div>}
              </FormGroup>

              : <FormGroup>
                <Label for="image" className="control-label col-sm-4">Intro Video</Label>
                <div className="col-sm-12">

                  <Button
                    color="primary"
                    disabled
                  >
                    <i className="fa fa-play" style={{ fontSize: '18px' }}></i>

                  </Button>
                  <i className="fa fa-trash" style={{ fontSize: '18px', marginLeft: '3%' }} onClick={this.handleDelete}></i>

                </div>
              </FormGroup>

            }
          </> : null}

          <FormGroup>
            <Label for="Active" className="control-label col-sm-4">Auto Whitelist</Label>
            <div className="col-sm-10">
              <input type="checkbox" id="issue-report-input2" name="feature_disabled" checked={this.state.organizationDetail.feature_disabled === false || this.state.organizationDetail.feature_disabled === 'false'} onChange={this.onHandleChange} /> Yes
            </div>
          </FormGroup>

          <FormGroup>
            <div className="col-sm-10"><Button className="btn-success">Submit</Button></div>
          </FormGroup>
        </Form>
    )
  }
}

export default AddEditForm
