/* eslint-disable no-unused-vars */

import React, { Component } from 'react'
import { Table } from 'reactstrap'
import ModalForm from '../Modals/brandModal'
import ReactTooltip from 'react-tooltip'
import '../css/dataTable.css'

class DataTable extends Component {
  render () {
    const imageConstant = 'https://assets-s3.twine.nyc/custom-branding/'
    const imageType = '.jpg'
    if (this.props.items.length === 0) {
      return (
        <Table responsive hover>
          <thead>
            <tr>
              <th>Id</th>
              <th>Domain Name</th>
              <th>Title</th>
              <th>Sub Title</th>
              <th>Space Id</th>
              <th>Logo</th>
              <th>Login logo</th>
              <th>Hero Image</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="12" align="center">No Records Found</td>
            </tr>
          </tbody>
        </Table>
      )
    } else {
      const items = this.props.items.map(item => {
        let logo, heroImage, loginLogo
        if ((item.hero_image_unique_id) != null) {
          heroImage = <img
            style={{ width: 50, height: 50 }}
            src={imageConstant + item.hero_image_unique_id + imageType}
            alt=""
          />
        }
        if ((item.logo_image_unique_id) != null) {
          logo = <img
            style={{ width: 50, height: 50 }}
            src={imageConstant + item.logo_image_unique_id + imageType}
            alt=""
          />
        }
        if ((item.login_logo_image_unique_id) != null) {
          loginLogo = <img
            style={{ width: 50, height: 50 }}
            src={imageConstant + item.login_logo_image_unique_id + imageType}
            alt=""
          />
        }
        return (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.domain_name}</td>
            <td data-tip={item.title}>{item.title.substring(0, 10)}...
              <ReactTooltip multiline={true} place="bottom" type="dark" />
            </td>
            {item.sub_title ? <td data-tip={item.sub_title}>{item.sub_title.substring(0, 10)}...
              <ReactTooltip multiline={true} place="bottom" type="dark" />
            </td> : <td></td>}
            <td>{item.space_id}</td>
            {logo ? <td>{logo}</td> : <td></td>}
            <td>{loginLogo}</td>
            <td>{heroImage}</td>
            <td>{(item.is_disabled === 'true' || item.is_disabled === true) ? 'No' : 'Yes'}</td>
            <td>
              <ModalForm buttonLabel="Edit" item={item} updateState={this.props.updateState} />
              {' '}
            </td>
          </tr>
        )
      })
      return (
        <Table responsive hover>
          <thead>
            <tr>
              <th>Id</th>
              <th>Domain Name</th>
              <th>Title</th>
              <th>Sub Title</th>
              <th>Space Id</th>
              <th>Logo</th>
              <th>Login logo</th>
              <th>Hero Image</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {items}
          </tbody>
        </Table>
      )
    }
  }
}

export default DataTable
