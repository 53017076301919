/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable camelcase */

import React from 'react'
import {
  Container, Row, Col, Form, FormGroup, Label, Input
} from 'reactstrap'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReservationTable from './Components/Tables/ReservationDataTable'
import WaitlistTable from './Components/Tables/WaitlistDataTable'
import PointlistTable from './Components/Tables/PointDataTable'
import ModalForm from './Components/Modals/Reservation'
import ModalFormPoints from './Components/Modals/Point'

class UserDetailForm extends React.Component {
  constructor (props) {
    super(props)
    this.handleActiveOptionChange = this.handleActiveOptionChange.bind(this)
    this.handleApprovedOptionChange = this.handleApprovedOptionChange.bind(this)
    this.state = {
      id: '',
      first_name: '',
      last_name: '',
      phone_number_code: '',
      phone_number: '',
      email: '',
      reservationList: [],
      waitingList: [],
      pointList: [],
      userId: '',
      is_disabled: '',
      is_approved: '',
      loading: false
    }
  }

  getUserDetail (inputUserId) {
    this.setState({ loading: true })
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/getUserById`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      },
      body: JSON.stringify({
        id: inputUserId
      })
    }).then((response) => response.json())
      .then((item) => {
        if (item.message === 'Success.') {
          const user = item.response_objects
          this.setState({
            id: user[0].id, first_name: user[0].first_name, last_name: user[0].last_name, phone_number_code: user[0].phone_number_code, phone_number: user[0].phone_number, email: user[0].email, is_disabled: user[0].is_disabled, is_approved: user[0].is_approved, loading: false
          })
        } else {
          // this.setState({ error_message: item.message});
        }
      })
      .catch((err) => console.log(err))
  }

  getReservationList (inputUserId) {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/getReservationsByUser`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      },
      body: JSON.stringify({
        id: inputUserId
      })
    }).then((response) => response.json())
      .then((item) => {
        if (item.message === 'Success.') {
          const reservationList = item.response_objects
          this.setState({ reservationList })
        } else {
          // this.setState({ error_message: item.message});
        }
      })
      .catch((err) => console.log(err))
  }

  getWaitingList (inputUserId) {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/getWaitlistsByUser`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      },
      body: JSON.stringify({
        id: inputUserId
      })
    }).then((response) => response.json())
      .then((item) => {
        if (item.message === 'Success.') {
          const waitingList = item.response_objects
          this.setState({ waitingList })
        } else {
          // this.setState({ error_message: item.message});
        }
      })
      .catch((err) => console.log(err))
  }

  getPointList (inputUserId) {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/getPointsByUser`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      },
      body: JSON.stringify({
        id: inputUserId
      })
    }).then((response) => response.json())
      .then((item) => {
        if (item.message === 'Success.') {
          const pointList = item.response_objects
          const total_Point = pointList[0].total_points
          this.setState({ pointList, total_Point })
        } else {
          // this.setState({ error_message: item.message});
        }
      })
      .catch((err) => console.log(err))
  }

  handleActiveOptionChange (event) {
    this.setState({
      is_disabled: event.target.value
    })
  }

  handleApprovedOptionChange (event) {
    this.setState({
      is_nda_accepted: event.target.value
    })
  }

  componentDidMount () {
    const user = this.props.location.pathname.split('/')[3]
    this.getUserDetail(user)
    this.getReservationList(user)
    this.getWaitingList(user)
    this.getPointList(user)
  }

  updateState (item) {
    window.location.reload()
  }

  render () {
    return (
      this.state.loading
        ? (
          <div id="loadingContent">
            <img align="absmiddle" alt="" src="/images/loading.gif" />
          </div>
        )
        : (
          <Container>

            <Form className="form-horizontal">
              <Row>
                <h3> User Information </h3>
              </Row>
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label for="first_name" className="control-label col-sm-8">First Name</Label>
                    <div className="col-sm-12"><Input type="text" name="first_name" id="first_name" value={this.state.first_name === null ? '' : this.state.first_name} readOnly /></div>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label for="last_name" className="control-label col-sm-8">Last Name</Label>
                    <div className="col-sm-12"><Input type="text" name="last_name" id="last_name" value={this.state.last_name === null ? '' : this.state.last_name} readOnly /></div>
                  </FormGroup>
                </Col>

                <Col xs="6">

                  <FormGroup>
                    <Label for="phone" className="control-label col-sm-8">Phone Number</Label>
                    <div className="col-sm-12">
                      <PhoneInput
                        inputProps={{
                          name: 'phone_number',
                          style: { width: '300px' }
                        }}
                        onChange={this.handleOnChange}
                        value={this.state.phone_number === null ? '' : (this.state.phone_number_code + this.state.phone_number)}
                        disabled
                      />

                    </div>
                  </FormGroup>
                </Col>

                <Col xs="6">

                  <FormGroup>
                    <Label for="email" className="control-label col-sm-2">Email</Label>
                    <div className="col-sm-12"><Input type="text" name="email" id="email" value={this.state.email === null ? '' : this.state.email} readOnly /></div>
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label for="Accept" className="control-label col-sm-8">Approved</Label>
                    <div className="col-sm-12">
                      <input type="radio" value="acceptfalse" name="accept" id="acceptYes" checked={this.state.is_approved === true || this.state.is_approved === 'true'} onChange={this.handleApprovedOptionChange} disabled />
                      {' '}
                      Yes
                      &nbsp;
                      <input type="radio" value="accepttrue" name="accept" id="acceptNo" checked={this.state.is_approved === false || this.state.is_approved === 'false'} onChange={this.handleApprovedOptionChange} disabled />
                      {' '}
                      No
                    </div>
                  </FormGroup>

                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label for="Active" className="control-label col-sm-4">Active</Label>
                    <div className="col-sm-12">
                      <input type="radio" value="false" name="active" id="activeYes" checked={this.state.is_disabled === false || this.state.is_disabled === 'false'} onChange={this.handleActiveOptionChange} disabled />
                      {' '}
                      Yes
                      &nbsp;
                      <input type="radio" value="true" name="active" id="activeNo" checked={this.state.is_disabled === true || this.state.is_disabled === 'true'} onChange={this.handleActiveOptionChange} disabled />
                      {' '}
                      No
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <h3> Reservation Information </h3>
                <Col>
                  <ModalForm buttonLabel="Add Reservation" itemId={this.state.id} updateState={this.updateState} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ReservationTable items={this.state.reservationList} />
                </Col>
              </Row>

              <Row>
                <h3> Waitlist Information </h3>
              </Row>

              <Row>
                <Col>
                  <WaitlistTable items={this.state.waitingList} />
                </Col>
              </Row>

              <Row>
                <h3> Points Information </h3>
                <Col>
                  <ModalFormPoints
                    buttonLabel="Add Points"
                    itemId={this.state.id}
                    updateState={this.updateState}
                  />
                  <Col>
                    <button type="button" className="btn btn-primary" style={{ float: 'left', marginLeft: '7px' }}>
                      Total Points :
                      {this.state.total_Point}
                    </button>
                  </Col>
                </Col>

              </Row>

              <Row>
                <Col>
                  <PointlistTable items={this.state.pointList} />
                </Col>
              </Row>

            </Form>

          </Container>
        )
    )
  }
}

export default UserDetailForm
