/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */
/* eslint-disable prefer-const */

import React from 'react'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { Upload, message } from 'antd'
import Select from 'react-select'
import { UploadOutlined } from '@ant-design/icons'

let maxLength = 100

class AddEditForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      brandDetail: {
        id: 0,
        domain_name: '',
        title: '',
        sub_title: 'Ready to meet fellow attendees? Sign up / log in below to enter.',
        is_disabled: false,
        hide_social_logins: false
      },
      logo_image_unique_id: '',
      login_logo_image_unique_id: '',
      hero_image_unique_id: '',
      spaceList: '',
      spaceSelect: '',
      errorMessage: '',
      errorMsg: {},
      loading: false,
      loginLogoImage: '',
      logoImage: '',
      heroImage: '',
      errDomainName: '',
      remainText: 100,
      subTitleRemainText: 100
    }
  }

  onChange = e => {
    const brandDetail = this.state.brandDetail
    brandDetail[e.target.name] = e.target.value
    this.setState({ brandDetail })
  }

  titleChange = e => {
    const brandDetail = this.state.brandDetail
    brandDetail[e.target.name] = e.target.value
    this.setState({
      brandDetail,
      remainText: maxLength - e.target.value.length
    })
  }

  subTitleChange = e => {
    const brandDetail = this.state.brandDetail
    brandDetail[e.target.name] = e.target.value
    this.setState({
      brandDetail,
      subTitleRemainText: maxLength - e.target.value.length
    })
  }

  domainChange = e => {
    const brandDetail = this.state.brandDetail
    brandDetail[e.target.name] = e.target.value
    this.setState({
      brandDetail,
      errDomainName: ''
    })
  }

  onFileChange = e => {
    e.target.name = e.target.value
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  validateBrandForm () {
    const { brandDetail, hero_image_unique_id, logo_image_unique_id, login_logo_image_unique_id } = this.state
    const error = {}
    let formValid = true

    if (brandDetail.is_disabled === '' || brandDetail.is_disabled === null) {
      error.is_disabled = 'Please Choose the Active'
      formValid = false
    }
    if (brandDetail.hide_social_logins === '' || brandDetail.hide_social_logins === null) {
      error.hide_social_logins = 'Please Choose Hide Social Logins'
      formValid = false
    }
    if (!brandDetail.domain_name) {
      error.domain_name = 'Please enter the domain name'
      formValid = false
    }
    if (brandDetail.domain_name && !brandDetail.domain_name.endsWith('.twine.nyc')) {
      error.domainError = 'Please enter the valid domain name "xxxx.twine.nyc"'
      formValid = false
    }
    if (!brandDetail.title) {
      error.title = 'Please Enter the title'
      formValid = false
    }
    if (!brandDetail.sub_title) {
      error.sub_title = 'Please Enter the sub title'
      formValid = false
    }
    if (!document.getElementById('hero_image_unique_id').value) {
      error.hero_image_unique_id = 'Please select the hero image'
      formValid = false
    }
    if (!this.state.logoImage) {
      error.logo_image_unique_id = 'Please select the logo'
      formValid = false
    }
    if (!this.state.loginLogoImage) {
      error.login_logo_image_unique_id = 'Please select the login logo'
      formValid = false
    }
    if (!this.state.heroImage) {
      error.hero_image_unique_id = 'Please select the hero image'
      formValid = false
    }
    if (!this.state.spaceSelect) {
      error.spaceSelect = 'Please select the space'
      formValid = false
    }

    this.setState({ errorMsg: error })
    return formValid
  }

  submitFormAdd = e => {
    e.preventDefault()
    if (!this.validateBrandForm()) {
    } else {
      this.setState({ loading: true })
      fetch(process.env.REACT_APP_API_ENDPOINT + '/brand/add', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          domain_name: escape(this.state.brandDetail.domain_name),
          logo_image_unique_id: document.getElementById('logo_image_unique_id').value,
          hero_image_unique_id: document.getElementById('hero_image_unique_id').value,
          login_logo_image_unique_id: document.getElementById('login_logo_image_unique_id').value,
          title: escape(this.state.brandDetail.title),
          sub_title: escape(this.state.brandDetail.sub_title),
          is_disabled: this.state.brandDetail.is_disabled,
          space_id: this.state.spaceSelect.id,
          hide_social_logins: this.state.brandDetail.hide_social_logins
        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            message.success('Custom Brand Added Successfully')
            this.props.addItemToState(item.response_objects)
            this.props.toggle()
            this.setState({ loading: false })
          } else {
            if (item.message === 'Domain name already exists') {
              this.setState({
                errDomainName: item.message
              })
            } else if (item.message === 'DNS already exists') {
              this.setState({
                errDomainName: item.message
              })
            }
            this.setState({
              loading: false
            })
          }
        })
        .catch(err => {
        })
    }
  }

  submitFormEdit = e => {
    e.preventDefault()
    if (!this.validateBrandForm()) {
    } else {
      this.setState({ loading: true })
      fetch(process.env.REACT_APP_API_ENDPOINT + '/brand/add', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          id: this.state.brandDetail.id,
          logo_image_unique_id: document.getElementById('logo_image_unique_id').value,
          hero_image_unique_id: document.getElementById('hero_image_unique_id').value,
          login_logo_image_unique_id: document.getElementById('login_logo_image_unique_id').value,
          title: escape(this.state.brandDetail.title),
          sub_title: escape(this.state.brandDetail.sub_title),
          is_disabled: this.state.brandDetail.is_disabled,
          space_id: this.state.spaceSelect.id,
          hide_social_logins: this.state.brandDetail.hide_social_logins
        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            message.success('Custom Brand Updated Successfully')
            this.props.updateState(item.response_objects)
            this.props.toggle()
            this.setState({ loading: false })
          } else {
            if (item.message === 'Domain name already exists') {
              this.setState({
                errDomainName: item.message
              })
            }
            this.setState({
              loading: false
            })
          }
        })
        .catch(err => {
          message.error(err)
        })
    }
  }

  handleOptionActiveChange = (event) => {
    const brandDetail = this.state.brandDetail
    brandDetail[event.target.name] = event.target.value
    this.setState({ brandDetail })
  }

  handleOptionHideSocialLoginsChange = (event) => {
    const brandDetail = this.state.brandDetail
    brandDetail[event.target.name] = event.target.value
    this.setState({ brandDetail })
  }

  fileOnLogoChange = (info) => {
    if (info.file.status !== 'uploading') {
    }
    if (info.file.status === 'done') {
      document.getElementById('logo_image_unique_id').value = info.file.response.image_unique_id
      message.success(`${info.file.name} file uploaded successfully`)
      this.setState({
        logoImage: info.file.response.image_unique_id,
        logo_image_unique_id: info.file.response.image_unique_id
      })
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    } else if (info.file.status === 'removed') {
      this.setState({
        logoImage: ''
      })
    }
  }

  fileOnLoginLogoChange = (info) => {
    if (info.file.status !== 'uploading') {
    }
    if (info.file.status === 'done') {
      document.getElementById('login_logo_image_unique_id').value = info.file.response.image_unique_id
      message.success(`${info.file.name} file uploaded successfully`)
      this.setState({
        loginLogoImage: info.file.response.image_unique_id,
        login_logo_image_unique_id: info.file.response.image_unique_id
      })
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    } else if (info.file.status === 'removed') {
      this.setState({
        loginLogoImage: ''
      })
    }
  }

  fileOnHeroChange = (info) => {
    if (info.file.status !== 'uploading') {
    }
    if (info.file.status === 'done') {
      document.getElementById('hero_image_unique_id').value = info.file.response.image_unique_id
      message.success(`${info.file.name} file uploaded successfully`)
      this.setState({
        heroImage: info.file.response.image_unique_id,
        hero_image_unique_id: info.file.response.image_unique_id
      })
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    } else if (info.file.status === 'removed') {
      this.setState({
        heroImage: ''
      })
    }
  }

  beforeUpload (file) {
    const isJpg = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'
    if (!isJpg) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      message.error('Image must smaller than 5MB!')
    }
    return isJpg && isLt5M
  }

  componentDidMount () {
    this.getSpace()
    // if item exists, populate the state with proper data
    let remainText = maxLength
    let subTitleRemainText = maxLength

    if (this.props.item) {
      const brandDetail = this.state.brandDetail

      if (this.props.item.sub_title) {
        subTitleRemainText = maxLength - this.props.item.sub_title.length
      }

      if (this.props.item.title) {
        remainText = maxLength - this.props.item.title.length
      }

      brandDetail.id = this.props.item.id
      brandDetail.title = this.props.item.title
      brandDetail.sub_title = this.props.item.sub_title ? this.props.item.sub_title : brandDetail.sub_title
      brandDetail.domain_name = this.props.item.domain_name
      brandDetail.is_disabled = this.props.item.is_disabled
      brandDetail.hide_social_logins = this.props.item.hide_social_logins
      this.setState({
        brandDetail,
        logo_image_unique_id: this.props.item.logo_image_unique_id,
        login_logo_image_unique_id: this.props.item.login_logo_image_unique_id,
        hero_image_unique_id: this.props.item.hero_image_unique_id,
        logoImage: this.props.item.logo_image_unique_id,
        loginLogoImage: this.props.item.login_logo_image_unique_id,
        heroImage: this.props.item.hero_image_unique_id,
        subTitleRemainText: subTitleRemainText
      })
    } else {
      subTitleRemainText = maxLength - this.state.brandDetail.sub_title.length
      this.setState({
        subTitleRemainText: subTitleRemainText
      })
    }
  }

  onSelectSpace = e => {
    this.setState({
      spaceSelect: e
    })
  }

  getSpace () {
    fetch(process.env.REACT_APP_API_ENDPOINT + '/space/getAllActive', {
      method: 'get',
      headers: {
        Authorization: window.localStorage.getItem('token')
      }
    })
      .then(response => response.json())
      .then(items => {
        if (Array.isArray(items.response_objects)) {
          const options = items.response_objects.map((data) => {
            return { id: data.id, value: data.name, label: data.name }
          })
          this.setState({
            spaceList: options
          })
          if (this.props.item && this.props.item.space_id !== undefined && this.props.item.space_id !== '') {
            items.response_objects.map((data) => {
              if (data.id === this.props.item.space_id) {
                const spaceDetails = { id: data.id, value: data.name, label: data.name }
                this.setState({
                  spaceSelect: spaceDetails
                })
              }
            })
          }
        } else {
          console.log('failure')
        }
      })
      .catch(err => console.log(err))
  }

  render () {
    const imageConstant = 'https://assets-s3.twine.nyc/custom-branding/'
    const imageType = '.jpg'
    const props = {
      name: 'image',
      action: process.env.REACT_APP_API_ENDPOINT + '/brand/upload',
      method: 'PUT',
      accept: '.jpg, .jpeg, .png',
      headers: {
        Authorization: window.localStorage.getItem('token')
      },
      multiple: false,
      showUploadList: false
    }
    return (
      this.state.loading
        ? <div id="loadingContent">
          <img align="absmiddle" alt="" src="../images/loading.gif" />
        </div>
        : <Container>
          <Form className="form-horizontal">
            <Row>
              <Col xs="6" style={{ marginTop: '1%', marginBottom: '2%' }}>
                <FormGroup>
                  <Label for="name" className="control-label col-sm-6">Domain Name</Label>
                  <div className="col-sm-12">
                    {this.props.item ? <Input type="text" disabled name="domain_name" id="domain_name" onChange={this.domainChange} value={this.state.brandDetail.domain_name === null ? '' : this.state.brandDetail.domain_name} />
                      : <Input type="text" name="domain_name" id="domain_name" onChange={this.domainChange} value={this.state.brandDetail.domain_name === null ? '' : this.state.brandDetail.domain_name} />}
                    {!this.state.brandDetail.domain_name ? <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.domain_name}</span> : ''}
                    {this.state.errDomainName ? <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errDomainName}</span> : ''}
                    {!this.state.brandDetail.domain_name.endsWith('.twine.nyc') ? <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.domainError}</span> : ''}
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6" style={{ marginTop: '1%', marginBottom: '2%' }}>
                <FormGroup>
                  <Label for="space" className="control-label col-sm-6">Spaces</Label>
                  <div className="col-sm-12">
                    {this.state.spaceList.length > 0 &&
                    <Select
                      options={this.state.spaceList}
                      value={this.state.spaceSelect}
                      onChange={this.onSelectSpace}
                      isMulti={false}
                      isSearchable={true}
                    />
                    }
                    {!this.state.spaceSelect ? <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.spaceSelect}</span> : '' }
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6" style={{ marginTop: '1%', marginBottom: '2%' }}>
                <FormGroup>
                  <Label for="title" className="control-label col-sm-6">Title</Label>
                  <div className="col-sm-12"><textarea className="form-control" name="title" id="title" onChange={this.titleChange} maxLength={100} value={this.state.brandDetail.title === null ? '' : this.state.brandDetail.title} />
                    <small className="form-text text-muted mt-2">{this.state.remainText} characters left</small>
                    {!this.state.brandDetail.title ? <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.title}</span> : '' }
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6" style={{ marginTop: '1%', marginBottom: '2%' }}>
                <FormGroup>
                  <Label for="sub_title" className="control-label col-sm-6">Sub Title</Label>
                  <div className="col-sm-12"><textarea className="form-control" name="sub_title" id="sub_title" onChange={this.subTitleChange} maxLength={100} value={this.state.brandDetail.sub_title === null ? '' : this.state.brandDetail.sub_title} />
                    <small className="form-text text-muted mt-2">{this.state.subTitleRemainText} characters left</small>
                    {!this.state.brandDetail.sub_title ? <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.sub_title}</span> : '' }
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6" style={{ marginTop: '1%', marginBottom: '2%' }}>
                <FormGroup>
                  <Label for="image" className="control-label col-sm-6">Logo</Label>
                  <div className="col-sm-12">
                    {this.state.logoImage ? <div><img
                      style={{ width: 75, height: 75 }}
                      src={imageConstant + this.state.logoImage + imageType}
                      alt=""
                    /> <br /> <br /> </div> : ''}
                    <Upload {...props}
                      beforeUpload={this.beforeUpload}
                      onChange={this.fileOnLogoChange}>
                      <Button type="button">
                        <UploadOutlined /> Click to Upload
                      </Button>
                    </Upload>
                    <Input type="hidden" name="logo_image_unique_id" id="logo_image_unique_id" onChange={this.onFileChange} value={this.state.logo_image_unique_id === null ? '' : this.state.logo_image_unique_id} /><br />
                    {!this.state.logoImage ? <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.logo_image_unique_id}</span> : ''}
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6" style={{ marginTop: '1%', marginBottom: '2%' }}>
                <FormGroup>
                  <Label for="image" className="control-label col-sm-6">Login Logo</Label>
                  <div className="col-sm-12">
                    {this.state.loginLogoImage ? <div><img
                      style={{ width: 75, height: 75 }}
                      src={imageConstant + this.state.loginLogoImage + imageType}
                      alt=""
                    /> <br /> <br /> </div> : ''}
                    <Upload {...props}
                      beforeUpload={this.beforeUpload}
                      onChange={this.fileOnLoginLogoChange}>
                      <Button type="button">
                        <UploadOutlined /> Click to Upload
                      </Button>
                    </Upload>
                    <Input type="hidden" name="login_logo_image_unique_id" id="login_logo_image_unique_id" onChange={this.onFileChange} value={this.state.login_logo_image_unique_id === null ? '' : this.state.login_logo_image_unique_id} /><br />
                    {!this.state.loginLogoImage ? <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.login_logo_image_unique_id}</span> : ''}
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6" style={{ marginTop: '1%', marginBottom: '2%' }}>
                <FormGroup>
                  <Label for="image" className="control-label col-sm-6">Hero Image</Label>
                  <div className="col-sm-12">
                    {this.state.heroImage ? <div> <img
                      style={{ width: 75, height: 75 }}
                      src={imageConstant + this.state.heroImage + imageType}
                      alt=""
                    /> <br /> <br /></div> : ''}
                    <Upload {...props}
                      beforeUpload={this.beforeUpload}
                      onChange={this.fileOnHeroChange}>
                      <Button type="button">
                        <UploadOutlined /> Click to Upload
                      </Button>
                    </Upload>
                    <Input type="hidden" name="hero_image_unique_id" id="hero_image_unique_id" onChange={this.onFileChange} value={this.state.hero_image_unique_id === null ? '' : this.state.hero_image_unique_id} /><br />
                    {!this.state.heroImage ? <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.hero_image_unique_id}</span> : ''}
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6" style={{ marginTop: '1%', marginBottom: '2%' }}>
                <FormGroup>
                  <Label for="Active" className="control-label col-sm-6">Active</Label>
                  <div className="col-sm-12">
                    <input type="radio" value="false" name="is_disabled" id="activeYes" checked={this.state.brandDetail.is_disabled === false || this.state.brandDetail.is_disabled === 'false'} onChange={this.handleOptionActiveChange} /> Yes
                &nbsp;<input type="radio" value="true" name="is_disabled" id="activeNo" checked={this.state.brandDetail.is_disabled === true || this.state.brandDetail.is_disabled === 'true'} onChange={this.handleOptionActiveChange} /> No
                    <div><span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.is_disabled}</span></div>
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6" style={{ marginTop: '1%', marginBottom: '2%' }}>
                <FormGroup>
                  <Label for="hide_social_logins" className="control-label col-sm-6">Hide Social Logins</Label>
                  <div className="col-sm-12">
                    <input type="radio" value="true" name="hide_social_logins" id="hideSocialLoginYes" checked={this.state.brandDetail.hide_social_logins === true || this.state.brandDetail.hide_social_logins === 'true'} onChange={this.handleOptionHideSocialLoginsChange} /> Yes
                &nbsp;<input type="radio" value="false" name="hide_social_logins" id="hideSocialLoginNo" checked={this.state.brandDetail.hide_social_logins === false || this.state.brandDetail.hide_social_logins === 'false'} onChange={this.handleOptionHideSocialLoginsChange} /> No
                    <div><span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.hide_social_logins}</span></div>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <div className="col-sm-2"></div>
              <div className="col-sm-10" style={{ marginLeft: '52%' }}><Button className="btn-success" onClick={this.props.item ? this.submitFormEdit : this.submitFormAdd}>Submit</Button></div>
            </FormGroup>
          </Form>
        </Container>
    )
  }
}

export default AddEditForm
