/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'

import { Layout, Menu } from 'antd'

import Category from './Category'
import Question from './Question'
import Topic from './Topic'

import User from './User'
import UserDetailView from './UserDetailView'
import Organization from './Organization'

import Room from './Room'
import Brand from './brand'
import Space from './space'
import { UserOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UploadOutlined } from '@ant-design/icons'
import FileUpload from './fileUpload'
import Composition from './Composition'

const { Header, Content, Footer, Sider } = Layout
const SubMenu = Menu.SubMenu

class Dashboard extends Component {
  state = {
    collapsed: false
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed })
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  componentDidMount () {
    document.title = 'CMS | Dashboard'
  }

  logout = () => {
    localStorage.clear()
    window.location.href = '/login'
  }

  render () {
    return (
      <Router>
        <Layout style={{ minHeight: '100vh' }}>

          <Sider
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}>
            <div className="logo" />

            <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
              <SubMenu
                key="sub1"
                title={
                  <span>
                    <UserOutlined /> Questions
                  </span>
                }
              >
                <Menu.Item key="1">Categories<Link to="/dashboard/categories" /></Menu.Item>
                <Menu.Item key="2">Topics<Link to="/dashboard/topics" /></Menu.Item>
                <Menu.Item key="3">Questions<Link to="/dashboard/questions" /></Menu.Item>

              </SubMenu>
              <Menu.Item key="10">Rooms<Link to="/dashboard/rooms" /></Menu.Item>
              <Menu.Item key="12">Spaces<Link to="/dashboard/spaces" /></Menu.Item>
              <Menu.Item key="11">Custom Brand<Link to="/dashboard/brand" /></Menu.Item>
              <Menu.Item key="8">Organizations<Link to="/dashboard/organizations" /></Menu.Item>
              <Menu.Item key="6"><UserOutlined /> Users<Link to="/dashboard/users" /></Menu.Item>
              <Menu.Item key="13"><UploadOutlined /> Imports<Link to="/dashboard/imports" /></Menu.Item>
              <Menu.Item key="14">Composition Rules<Link to="/dashboard/compositions" /></Menu.Item>
              <Menu.Item key="7"><LogoutOutlined /> Logout<Link to="#" onClick={this.logout} /></Menu.Item>
            </Menu>

          </Sider>
          <Layout>
            <Header style={{ background: '#fff', padding: 0, paddingLeft: 16 }}>
              {this.state.collapsed ? <MenuUnfoldOutlined onClick={this.toggle} /> : <MenuFoldOutlined onClick={this.toggle} />}
            </Header>
            <Content style={{ margin: '24px 16px', padding: 24, background: '#fff', minHeight: 280 }}>
              <Route exact path="/dashboard" component={Category} />
              <Route path="/dashboard/categories" component={Category} />
              <Route path="/dashboard/topics" component={Topic} />
              <Route path="/dashboard/questions" component={Question} />
              <Route path="/dashboard/users" component={User} />
              <Route path="/dashboard/userView/:id" component={UserDetailView} />
              <Route path="/dashboard/organizations" component={Organization} />
              <Route path="/dashboard/rooms" component={Room} />
              <Route path="/dashboard/brand" component={Brand} />
              <Route path="/dashboard/spaces" component={Space} />
              <Route path="/dashboard/imports" component={FileUpload} />
              <Route path="/dashboard/compositions" component={Composition} />
            </Content>

            <Footer style={{ textAlign: 'center' }}>Copyright © 2020 Gathering. All Rights Reserved</Footer>

          </Layout>

        </Layout>
      </Router>
    )
  }
}

export default Dashboard
