/* eslint-disable no-unused-vars */
/* eslint-disable */

import React from 'react'
import { Button, Form, FormGroup, Label } from 'reactstrap'
import { message } from 'antd'
import AutoComplete from 'material-ui/AutoComplete'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'

class AddEditForm extends React.Component {
  constructor (props) {
    super(props)
    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.state = {
      user_id: this.props.item,
      gathering_id: '',
      is_disabled: false,
      gatheringSuggestList: [],
      loading: false
    }
  }

	onChange = e => {
	  this.setState({ [e.target.name]: e.target.value })
	}

	submitFormAdd = (e) => {
	  e.preventDefault()
	  this.setState({ loading: true })
	  fetch(process.env.REACT_APP_API_ENDPOINT + '/users/addReservation', {
	    method: 'post',
	    headers: {
	      'Content-Type': 'application/json',
	      Authorization: window.localStorage.getItem('token')
	    },
	    body: JSON.stringify({
	      user_id: this.props.item,
	      gathering_id: this.state.gathering_id,
	      is_disabled: this.state.is_disabled

	    })
	  })
	    .then(response => response.json())
	    .then(item => {
	      if (item.message === 'Success.') {
	        message.success('Reservation Added Successfully')
	        this.props.updateState(item.response_objects)
	        this.props.toggle()
	        this.setState({ loading: false })
	      } else {
	        message.error('Please select the valid gathering from the list')
            		this.props.toggle()
	      }
	    })
	    .catch(err => {
	      message.error(err)
	    })
	}

	handleOptionChange (event) {
	  this.setState({
	    is_disabled: event.target.value
	  })
	}

	componentDidMount () {

	}

	gatheringSelectedItem = (e) => {
	  this.state.gatheringSuggestList.forEach((item) => {
	    if (item.name === e) {
	      this.setState({ gathering_id: item.id })
	    }
	  })
	}

	gatheringAutoComplete = (e) => {
	  let userInput = e.replace(/\//g, '')
	  userInput = userInput.replace('#', '')
	  this.setState({ retInput: e, gathering_name: userInput })
	  if (userInput.length > 2) {
	    fetch(process.env.REACT_APP_API_ENDPOINT + '/gatherings/loadByName', {
	      method: 'post',
	      headers: {
	        Authorization: window.localStorage.getItem('token'),
	        'Content-Type': 'application/json'
	      },
	      body: JSON.stringify({
	        searchString: userInput
	      })
	    })
	      .then(response => response.json())
	      .then(item => {
	        if (item.message === 'Success.') {
	          var items = item.response_objects
	          this.setState({ gatheringSuggestList: items })
	        } else {
	          this.setState({ gatheringSuggestList: [] })
	        }
	      })
	      .catch(err => console.log(err))
	  } else {
	    this.setState({ gatheringSuggestList: [] })
	  }
	}

	render () {
	  return (
	    this.state.loading
	      ? <div id="loadingContent">
	        <img align="absmiddle" alt="" src="../../images/loading.gif" />
	      </div>
	      : <Form onSubmit={this.submitFormAdd} className="form-horizontal">

	        <FormGroup>
	          <Label for="Gathering" className="control-label col-sm-2">Gathering</Label>
	          <div className="col-sm-10">
	            <MuiThemeProvider>
	              <AutoComplete
	                id='gathering-input'
	                openOnFocus={true}
	                menuStyle={{ height: 200, overflowX: 'scroll' }}
	                filter={AutoComplete.fuzzyFilter}
	                dataSource={(this.state.gatheringSuggestList !== null && this.state.gatheringSuggestList.map((item) => item.name)) || []}
	                maxSearchResults={9}
	                onUpdateInput={(e) => this.gatheringAutoComplete(e)}
	                onNewRequest={(e) => this.gatheringSelectedItem(e)}
	                open={true}
	                fullWidth={true}
	                hintText="Type Gathering"
	                searchText={this.state.gathering_name}
	                value={this.state.gathering_name}
	              />
	            </MuiThemeProvider>
	          </div>
	        </FormGroup>

	        <FormGroup>
	          <div className="col-sm-1"></div>
	          <div className="col-sm-10"><Button className="btn-success">Submit</Button></div>
	        </FormGroup>

	      </Form>
	  )
	}
}
export default AddEditForm
