/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import React from 'react'
import { Container, Row, Col, Form, Input } from 'reactstrap'
import FileUploadModal from './Components/Modals/fileUploadModal'
import DataTable from './Components/Tables/fileDataTable'
import ReactPaginate from 'react-paginate'
import { DownloadOutlined } from '@ant-design/icons'

class FileUpload extends React.Component {
  state = {
    items: [],
    searchName: '',
    pageSize: 10,
    pageNo: 1,
    pageCount: 1,
    loading: false
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleClick = (e) => {
    if (e) { e.preventDefault() }
    this.getItems(1)
  }

  getItems (pageNumber) {
    this.setState({ loading: true })
    fetch(process.env.REACT_APP_API_ENDPOINT + '/file_import', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      },
      body: JSON.stringify({
        searchName: escape(this.state.searchName),
        pageSize: this.state.pageSize,
        pageNo: pageNumber
      })
    }).then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          var items = item.response_objects
          this.setState({ items, pageCount: Math.ceil(item.totalCount / this.state.pageSize), loading: false })
        }
      })
      .catch(err => console.log(err))
  }

  addItemToState = (item) => {
    this.setState({ searchName: '' })
    this.getItems(this.state.pageNo)
  }

  componentDidMount () {
    this.getItems(this.state.pageNo)
  }

  handlePageClick (data) {
    let selected = data.selected
    selected = selected + 1
    this.setState({ pageNo: selected })
    this.getItems(selected)
  }

  render () {
    return (
      this.state.loading
        ? <div id="loadingContent">
          <img align="absmiddle" alt="" src="../images/loading.gif" />
        </div>
        : <Container className="file-upload">
          <Row>
            <Col>
              <FileUploadModal buttonLabel="New Import" addItemToState={this.addItemToState} />
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 style={{ margin: '20px 0' }}>File List</h1>
            </Col>
            <h4 className='mt-5'>
              <a href='../asset/sample-file-import.csv'>Sample Users Import <DownloadOutlined /></a>
            </h4>
          </Row>
          <Row>
            <Col>
              <DataTable items={this.state.items} getItems={this.getItems} deleteItemFromState={this.deleteItemFromState} />
            </Col>
          </Row>

          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break'}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={5}
            pageRangeDisplayed={5}
            forcePage={this.state.pageNo - 1}
            onPageChange={this.handlePageClick.bind(this)}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />

        </Container>
    )
  }
}

export default FileUpload
