/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import React, { Component } from 'react'
import { Table } from 'reactstrap'
import ModalForm from '../Modals/Category'

class DataTable extends Component {
  deleteItem = id => {
    const confirmDelete = window.confirm('Are you sure want to delete?')
    if (confirmDelete) {
      fetch(process.env.REACT_APP_API_ENDPOINT + '/crud/deleteCategory', {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id
        })
      })
        .then(response => response.json())
        .then(item => {
          this.props.deleteItemFromState(id)
        })
        .catch(err => console.log(err))
    }
  }

  render () {
    if (this.props.items.length === 0) {
      return (
        <Table responsive hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Category</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="3" align="center">No Records Found</td>
            </tr>
          </tbody>
        </Table>
      )
    } else {
      const items = this.props.items.map(item => {
        return (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.category}</td>
            <td>{(item.is_disabled === 'true' || item.is_disabled === true) ? 'No' : 'Yes'}</td>
            <td>
              <div style={{ width: '110px' }}>
                <ModalForm buttonLabel="Edit" item={item} updateState={this.props.updateState} />
                {' '}
              </div>
            </td>
          </tr>
        )
      })
      return (
        <Table responsive hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Category</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {items}
          </tbody>
        </Table>
      )
    }
  }
}

export default DataTable
