/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import React from 'react'
import { Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap'
import Select from 'react-select'

class AddEditForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      compositionDetail: {
        id: '',
        name: '',
        rules: '',
        rules_sql: '',
        is_disabled: false
      },
      loading: false,
      errorMsg: {},
      spaceList: '',
      spaceSelect: ''
    }
  }

    onChange = e => {
      const compositionDetail = this.state.compositionDetail
      compositionDetail[e.target.name] = e.target.value
      this.setState({ compositionDetail })
    }

      onSelectSpace = e => {
        this.setState({
          spaceSelect: e
        })
      }

      rulesChange = e => {
        const compositionDetail = this.state.compositionDetail
        compositionDetail[e.target.name] = e.target.value
        if (typeof e.target.value === 'object') {
          this.setState({
            rulesError: ''
          })
        }
        this.setState({
          compositionDetail
        })
      }

      rulesSql = e => {
        const compositionDetail = this.state.compositionDetail
        compositionDetail[e.target.name] = e.target.value
        this.setState({
          compositionDetail
        })
      }

      handleOptionActiveChange = (event) => {
        const compositionDetail = this.state.compositionDetail
        compositionDetail[event.target.name] = event.target.value
        this.setState({ compositionDetail })
      }

      jsonValid = (item) => {
        item = typeof item !== 'string'
          ? JSON.stringify(item)
          : item

        try {
          item = JSON.parse(item)
        } catch (e) {
          return false
        }

        if (typeof item === 'object' && item !== null) {
          return true
        }

        return false
      }

      validateCompositionForm () {
        const { compositionDetail } = this.state
        const error = {}
        let formValid = true

        if (!compositionDetail.name) {
          error.name = 'Please enter the name'
          formValid = false
        }
        if (!compositionDetail.rules) {
          error.rules = 'Please Enter the rules'
          formValid = false
        } else if (!this.jsonValid(compositionDetail.rules)) {
          error.rules = 'Please enter rules in json format.'
          formValid = false
        }
        if (!this.state.spaceSelect) {
          error.spaceSelect = 'Please select the space'
          formValid = false
        }

        this.setState({ errorMsg: error })
        return formValid
      }

      submitFormAdd = e => {
        e.preventDefault()
        if (!this.validateCompositionForm()) {
        } else {
          this.setState({ loading: true })
          fetch(process.env.REACT_APP_API_ENDPOINT + '/composition_rules/add', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              Authorization: window.localStorage.getItem('token')
            },
            body: JSON.stringify({
              name: escape(this.state.compositionDetail.name),
              rules: JSON.parse(this.state.compositionDetail.rules),
              is_disabled: this.state.compositionDetail.is_disabled,
              space_id: this.state.spaceSelect.id
            })
          })
            .then(response => response.json())
            .then(item => {
              if (item.message === 'Success.') {
                this.props.toggle()
                this.props.addItemToState(item.response_objects)
                this.setState({ loading: false })
              } else {
                this.setState({
                  loading: false
                })
              }
            })
            .catch(err => {
              console.log(err)
            })
        }
      }

    submitFormEdit = e => {
      e.preventDefault()
      if (!this.validateCompositionForm()) {
      } else {
        this.setState({ loading: true })
        fetch(process.env.REACT_APP_API_ENDPOINT + '/composition_rules/add', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            Authorization: window.localStorage.getItem('token')
          },
          body: JSON.stringify({
            id: this.state.compositionDetail.id,
            name: escape(this.state.compositionDetail.name),
            rules: JSON.parse(this.state.compositionDetail.rules),
            is_disabled: this.state.compositionDetail.is_disabled,
            space_id: this.state.spaceSelect.id
          })
        })
          .then(response => response.json())
          .then(item => {
            if (item.message === 'Success.') {
              this.props.updateState(item.response_objects)
              this.props.toggle()
              this.setState({ loading: false })
            } else {
              if (item.message === 'Domain name already exists') {
                this.setState({
                  errDomainName: item.message
                })
              }
              this.setState({
                loading: false
              })
            }
          })
          .catch(err => {
            message.error(err)
          })
      }
    }

    getSpace () {
      fetch(process.env.REACT_APP_API_ENDPOINT + '/space/getAllActive', {
        method: 'get',
        headers: {
          Authorization: window.localStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(items => {
          if (Array.isArray(items.response_objects)) {
            const options = items.response_objects.map((data) => {
              return { id: data.id, value: data.name, label: data.name }
            })
            this.setState({
              spaceList: options
            })
            if (this.props.item && this.props.item.space_id !== undefined && this.props.item.space_id !== '') {
              items.response_objects.map((data) => {
                if (data.id === this.props.item.space_id) {
                  const spaceDetails = { id: data.id, value: data.name, label: data.name }
                  this.setState({
                    spaceSelect: spaceDetails
                  })
                }
              })
            }
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
    }

    componentDidMount () {
      this.getSpace()
      // if item exists, populate the state with proper data
      if (this.props.item) {
        const compositionDetail = this.state.compositionDetail

        compositionDetail.id = this.props.item.id
        compositionDetail.name = this.props.item.name
        compositionDetail.is_disabled = this.props.item.is_disabled
        compositionDetail.rules = JSON.stringify(this.props.item.rules),
        compositionDetail.rules_sql = this.props.item.rules_sql

        this.setState({
          compositionDetail
        })
      }
    }

    render () {
      return (
        this.state.loading
          ? <div id="loadingContent">
            <img align="absmiddle" alt="" src="../images/loading.gif" />
          </div>
          : <Form className="form-horizontal">
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label for="name" className="control-label col-sm-6">Name</Label>
                  <div className="col-sm-12">
                    <Input type="text" name="name" id="name" onChange={this.onChange} value={this.state.compositionDetail.name} />
                    <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.name}</span>
                  </div>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label for="space" className="control-label col-sm-6">Spaces</Label>
                  <div className="col-sm-12">
                    {this.state.spaceList.length > 0 &&
                    <Select
                      options={this.state.spaceList}
                      value={this.state.spaceSelect}
                      onChange={this.onSelectSpace}
                      isMulti={false}
                      isSearchable={true}
                    />
                    }
                    {!this.state.spaceSelect ? <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.spaceSelect}</span> : '' }
                  </div>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label for="rules" className="control-label col-sm-6">Rules</Label>
                  <div className="col-sm-12"><textarea placeholder='{"value":""}' className="form-control" name="rules" id="rules" onChange={this.rulesChange} value={this.state.compositionDetail.rules === null ? '' : this.state.compositionDetail.rules} />

                    {!this.state.compositionDetail.rules && <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.rules}</span>}
                    {this.state.compositionDetail.rules ? <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.rules}</span> : ''}
                  </div>
                </FormGroup>
              </Col>
              {this.props.item && <Col xs="6">
                <FormGroup>
                  <Label for="rules_sql" className="control-label col-sm-6">Rules SQL</Label>
                  <div className="col-sm-12"><textarea disabled className="form-control" name="rules_sql" id="rules_sql" onChange={this.rulesSql} value={this.state.compositionDetail.rules_sql === null ? '' : this.state.compositionDetail.rules_sql} />
                  </div>
                </FormGroup>
              </Col>}
              <Col xs="6">
                <FormGroup>
                  <Label for="Active" className="control-label col-sm-6">Active</Label>
                  <div className="col-sm-12">
                    <input type="radio" value="false" name="is_disabled" id="activeYes" checked={this.state.compositionDetail.is_disabled === false || this.state.compositionDetail.is_disabled === 'false'} onChange={this.handleOptionActiveChange} /> Yes
                &nbsp;<input type="radio" value="true" name="is_disabled" id="activeNo" checked={this.state.compositionDetail.is_disabled === true || this.state.compositionDetail.is_disabled === 'true'} onChange={this.handleOptionActiveChange} /> No
                    <div><span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.is_disabled}</span></div>
                  </div>
                </FormGroup>
              </Col>
              <FormGroup>
                <div className="col-sm-10"><Button className="btn-success" onClick={this.props.item ? this.submitFormEdit : this.submitFormAdd}>Submit</Button></div>
              </FormGroup>
            </Row>
          </Form>

      )
    }
}

export default AddEditForm
