/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import React from 'react'
import { Container, Row, Col, Form, Input } from 'reactstrap'
import ModalForm from './Components/Modals/brandModal'
import DataTable from './Components/Tables/brandDataTable'
import ReactPaginate from 'react-paginate'

class Brand extends React.Component {
  state = {
    items: [],
    searchName: '',
    pageSize: 10,
    pageNo: 1,
    pageCount: 1,
    loading: false
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleClick = (e) => {
    if (e) { e.preventDefault() }
    this.getItems(1)
  }

  getItems (pageNumber) {
    this.setState({ loading: true })
    fetch(process.env.REACT_APP_API_ENDPOINT + '/brand', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      },
      body: JSON.stringify({
        searchName: escape(this.state.searchName),
        pageSize: this.state.pageSize,
        pageNo: pageNumber
      })
    }).then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          const items = item.response_objects
          this.setState({ items, pageCount: Math.ceil(item.totalCount / this.state.pageSize), loading: false })
        } else {
          this.setState({ error_message: item.message })
        }
      })
      .catch(err => console.log(err))
  }

  addItemToState = (item) => {
    this.setState({ searchName: '' })
    this.getItems(this.state.pageNo)
  }

  updateState = (item) => {
    this.setState({ searchName: '' })
    this.getItems(this.state.pageNo)
  }

  componentDidMount () {
    this.getItems(this.state.pageNo)
  }

  handlePageClick (data) {
    let selected = data.selected
    selected = selected + 1
    this.setState({ pageNo: selected })
    this.getItems(selected)
  }

  render () {
    return (
      this.state.loading
        ? <div id="loadingContent">
          <img align="absmiddle" alt="" src="../images/loading.gif" />
        </div>
        : <Container className="brand">
          <Row>
            <Col>
              <ModalForm buttonLabel="Add Custom Brand" addItemToState={this.addItemToState} />
            </Col>
            <Form onSubmit={this.handleClick} className="form-horizontal">

              <Row>

                <Col className="" style={{ width: '300px', marginLeft: '-50px' }}>
                  <Input type="text" name="searchName" placeholder="Search..." id="searchName" onChange={this.onChange} value={this.state.searchName === null ? '' : this.state.searchName} />
                </Col>
                <Col xs="2" style={{ marginLeft: '-25px', paddingTop: '1px' }}>
                  <button type="submit"><i className="fa fa-search"></i></button>
                </Col>

              </Row>
            </Form>

          </Row>
          <Row>
            <Col>
              <h1 style={{ margin: '20px 0' }}>Custom Brand List</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable items={this.state.items} updateState={this.updateState} />
            </Col>
          </Row>

          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break'}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={5}
            pageRangeDisplayed={5}
            forcePage={this.state.pageNo - 1}
            onPageChange={this.handlePageClick.bind(this)}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />

        </Container>
    )
  }
}

export default Brand
