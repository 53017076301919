/* eslint-disable no-unused-vars */

import React, { Component } from 'react'
import { Table } from 'reactstrap'
import moment from 'moment'

class DataTable extends Component {
  render () {
    if (this.props.items.length === 0) {
      return (
        <Table responsive hover>
          <thead>
            <tr>
              <th>Name </th>
              <th>Starts On</th>
              <th>Ends On</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="4" align="center">No Records Found</td>
            </tr>
          </tbody>
        </Table>
      )
    }
    const items = this.props.items.map((item) => (
      <tr key={item.id}>
        <td>{item.name}</td>
        <td>{moment(item.starts_on).local().format('MM/DD/YYYY HH:mm:ss a')}</td>
        <td>{moment(item.ends_on).local().format('MM/DD/YYYY HH:mm:ss a')}</td>
        <td>Waiting</td>
      </tr>
    ))
    return (
      <Table responsive hover>
        <thead>
          <tr>
            <th>Name </th>
            <th>Starts On</th>
            <th>Ends On</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
      </Table>
    )
  }
}

export default DataTable
