/* eslint-disable no-unused-vars */

import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import AddEditForm from '../Forms/brandFormAddEdit'

class ModalForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modal: false
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
  }

  render () {
    const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>

    const label = this.props.buttonLabel

    let button = ''
    let title = ''

    if (label === 'Edit') {
      button = <Button
        color="warning"
        onClick={this.toggle}
        style={{ float: 'left', marginRight: '10px' }}>{label}
      </Button>
      title = 'Edit Custom Brand'
    } else {
      button = <Button
        color="success"
        onClick={this.toggle}
        style={{ float: 'left', marginRight: '10px' }}>{label}
      </Button>
      title = 'Add Custom Brand'
    }

    return (
      <div >
        {button}
        <Modal isOpen={this.state.modal} toggle={this.toggle} style={{ maxWidth: '850px' }} className="" >
          <ModalHeader toggle={this.toggle} close={closeBtn}>{title}  </ModalHeader>
          <ModalBody>
            <AddEditForm
              addItemToState={this.props.addItemToState}
              updateState={this.props.updateState}
              toggle={this.toggle}
              item={this.props.item} />
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default ModalForm
