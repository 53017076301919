/* eslint-disable no-unused-vars */
/* eslint-disable */

import React from 'react'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { message } from 'antd'

class AddEditForm extends React.Component {
  constructor (props) {
    super(props)
    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.handleDropdownChange = this.handleDropdownChange.bind(this)
    this.state = {
      user_id: this.props.item,
      pointList: [],
      point_id: '',
      points: '',
      is_disabled: false,
      loading: false
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  getAllPoints () {
    fetch(process.env.REACT_APP_API_ENDPOINT + '/users/getAllPoints', {
      method: 'get',
      headers: {
        Authorization: window.localStorage.getItem('token')
      }
    })
      .then(response => response.json())
      .then(items => {
        if (Array.isArray(items.response_objects)) {
          const options = items.response_objects.map((data) =>
            <option
              key={data.id}
              value={data.id}
            >
              {data.name}
            </option>
          )
          this.setState({
            pointList: options
          })
        } else {
          console.log('failure')
        }
      })
      .catch(err => console.log(err))
  }

  submitFormAdd = (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    fetch(process.env.REACT_APP_API_ENDPOINT + '/users/addPoints', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      },
      body: JSON.stringify({
        user_id: this.props.item,
        point_id: this.state.point_id,
        points: this.state.points,
        is_disabled: this.state.is_disabled
      })
    })
      .then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          message.success('Points Added Successfully')
          this.props.updateState(item.response_objects)
          this.props.toggle()
          this.setState({ loading: false })
        } else {
          message.error(item.message)
        }
      })
      .catch(err => {
        message.error(err)
      })
  }

  handleOptionChange (event) {
    this.setState({
      is_disabled: event.target.value
    })
  }

  handleDropdownChange (e) {
    this.setState({
      point_id: e.target.value
    })
  }

  componentDidMount () {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const { user_id, point_id, points, is_disabled } = this.props.item
      this.setState({ user_id, point_id, points, is_disabled })
    }
    this.getAllPoints()
  }

  gatheringSelectedItem = (e) => {
    this.state.gatheringSuggestList.forEach((item) => {
      if (item.name === e) {
        this.setState({ gathering_id: item.id })
      }
    })
  }

  gatheringAutoComplete = (e) => {
    let userInput = e.replace(/\//g, '')
    userInput = userInput.replace('#', '')
    this.setState({ retInput: e, gathering_name: userInput })
    if (userInput.length > 2) {
      fetch(process.env.REACT_APP_API_ENDPOINT + '/gatherings/loadByName', {
        method: 'post',
        headers: {
          Authorization: window.localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          searchString: userInput
        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            var items = item.response_objects
            this.setState({ gatheringSuggestList: items })
          } else {
            this.setState({ gatheringSuggestList: [] })
          }
        })
        .catch(err => console.log(err))
    } else {
      this.setState({ gatheringSuggestList: [] })
    }
  }

  render () {
    return (
      this.state.loading
        ? <div id="loadingContent">
          <img align="absmiddle" alt="" src="../../images/loading.gif" />
        </div>
        : <Form onSubmit={this.submitFormAdd} className="form-horizontal">

          <FormGroup>
            <Label for="category" className="control-label col-sm-8">Point Type</Label>
            <div className="col-sm-10">
              <select name="name" className="form-control" onChange={this.handleDropdownChange} value={this.state.point_id}>
                <option>Select Point Type</option>
                {this.state.pointList}
              </select>
            </div>
          </FormGroup>

          <FormGroup>
            <Label for="points" className="control-label col-sm-2">Points</Label>
            <div className="col-sm-10"><Input type="text" name="points" id="points" onChange={this.onChange} value={this.state.points === null ? '' : this.state.points} /></div>
          </FormGroup>

          <FormGroup>
            <div className="col-sm-1"></div>
            <div className="col-sm-10"><Button className="btn-success">Submit</Button></div>
          </FormGroup>

        </Form>
    )
  }
}
export default AddEditForm
