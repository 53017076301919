/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */

import React from 'react'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { Upload, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { FILE_UPLOAD_MESSAGES } from '../constants'

class FileAddForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      fileDetail: {
        id: 0,
        type_id: 1,
        is_disabled: false
      },
      file_unique_id: '',
      importFile: '',
      searchName: '',
      pageSize: 10,
      errorMsg: {},
      fileUpload: '',
      typeSelect: 'Users Import',
      loading: false,
      fileUploading: false,
      fileList: []
    }
  }

  onChange = e => {
    const fileDetail = this.state.fileDetail
    fileDetail[e.target.name] = e.target.value
    this.setState({ fileDetail })
  }

  handleOptionActiveChange = (event) => {
    const fileDetail = this.state.fileDetail
    fileDetail[event.target.name] = event.target.value
    this.setState({ fileDetail })
  }

  onSelectType = type => {
    this.setState({
      typeSelect: type.target.value
    })
  }

  validateFileForm () {
    let fileErrorMsg = ''
    let formValid = true

    if (!this.state.file_unique_id) {
      fileErrorMsg = 'Please choose the file'
      formValid = false
    }

    this.setState({ fileErrorMsg })
    return formValid
  }

  submitFormAdd = e => {
    let typeId
    if (this.state.typeSelect === 'Users Import') {
      typeId = 1
    } else if (this.state.typeSelect === 'Rooms Import') {
      typeId = 2
    }
    e.preventDefault()
    if (!this.validateFileForm()) {
    } else {
      this.setState({ loading: true })
      fetch(process.env.REACT_APP_API_ENDPOINT + '/file_import/add', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          type_id: typeId,
          file_unique_id: this.state.file_unique_id,
          is_disabled: this.state.fileDetail.is_disabled
        })
      })
        .then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {
            message.success('File Added Successfully')
            this.props.addItemToState(item.response_objects)
            this.props.toggle()
            this.setState({ loading: false })
          } else {
            message.error(item.message)
            this.setState({ loading: false })
          }
        })
        .catch(err => {
        })
    }
  }

  fileOnChange = (info) => {
    let fileList = [...info.fileList]

    fileList = fileList.slice(-1)
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.url
      }
      return file
    })

    this.setState({ fileList })

    if (info.file.status === 'uploading') {
      this.setState({
        fileUploading: true
      })
    } else if (info.file.status === 'done') {
      document.getElementById('file_unique_id').value = info.file.response.file_unique_id
      message.success(`${info.file.name} file uploaded successfully`)
      this.setState({
        file_unique_id: info.file.response.file_unique_id,
        fileUploading: false
      })
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    } else if (info.file.status === 'removed') {
      this.setState({
        logoImage: ''
      })
    }
  }

  beforeUpload = (file) => {
    const isCSV = file.type === 'text/csv' || 'application/vnd.ms-excel'
    if (!isCSV) {
      message.error(FILE_UPLOAD_MESSAGES.fileUploadErrorMsg)
      this.setState({
        fileErrorMsg: FILE_UPLOAD_MESSAGES.fileUploadErrorMsg
      })
    } else {
      this.setState({
        fileErrorMsg: ''
      })
    }
    return isCSV
  }

  fileUpload = () => {
    if (this.state.fileUpload) {
    }
  }

  componentDidMount () {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const id = this.props.item.id
      const fileDetail = this.state.fileDetail
      fileDetail.id = this.props.item.id
      fileDetail.type_id = this.props.item.type_id
      fileDetail.is_disabled = this.props.item.is_disabled
      this.setState({ id: id, fileDetail })
    }
  }

  render () {
    const typeList = []
    typeList.push(<option key={'1'}>{'Users Import'}</option>)
    // typeList.push(<option key={'2'}>{'Rooms Import'}</option>)
    const props = {
      name: 'csv',
      action: process.env.REACT_APP_API_ENDPOINT + '/file_import/upload',
      method: 'PUT',
      accept: '.csv',
      headers: {
        Authorization: window.localStorage.getItem('token')
      },
      multiple: false,
      showUploadList: { showDownloadIcon: false, showRemoveIcon: true }
    }
    return (
      this.state.loading
        ? <div id="loadingContent">
          <img align="absmiddle" alt="" src="../images/loading.gif" />
        </div>
        : <Container>
          <Form className="form-horizontal">
            <Row>

              <Col xs="6">
                <FormGroup>
                  <Label for="file_upload" className="control-label col-sm-12">File Import</Label>
                  <div className="col-sm-12">
                    {this.state.fileUploading
                      ? <div><p className='text-primary'>Uploading ....</p></div>
                      : ''}
                    <Upload {...props}
                      fileList={this.state.fileList}
                      beforeUpload={this.beforeUpload}
                      onChange={this.fileOnChange}
                    >
                      <Button type="button">
                        <UploadOutlined /> Click to Upload
                      </Button>
                    </Upload>
                    {this.state.fileErrorMsg ? <div>
                      <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.fileErrorMsg}</span>
                    </div>
                      : ''}
                    <Input type="hidden" name="file_unique_id" id="file_unique_id" onChange={this.onChange} value={this.state.file_unique_id === null ? '' : this.state.file_unique_id} />
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="max_convo_partners" className="control-label col-sm-12">Type</Label>
                  <div className="col-sm-12">
                    <select name="type-select" className="form-control" onChange={this.onSelectType} value={this.state.typeSelect}>
                      {typeList}
                    </select>
                    {!this.state.typeSelect ? <span style={{ color: '#d31820', fontSize: '14' }}>{this.state.errorMsg.typeSelect}</span> : '' }
                  </div>
                </FormGroup>
              </Col>

            </Row>

            <FormGroup>
              <div className="col-sm-2"></div>
              <div className="col-sm-10"><Button className="btn-success" onClick={this.submitFormAdd}>Submit</Button></div>
            </FormGroup>
          </Form>
        </Container>
    )
  }
}

export default FileAddForm
