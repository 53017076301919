/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable camelcase */

import React from 'react'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { message } from 'antd'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Multiselect } from 'multiselect-react-dropdown'
import Select from 'react-select'
import { CONSTANTS } from '../constants'

class AddEditForm extends React.Component {
  constructor (props) {
    super(props)
    this.handleActiveOptionChange = this.handleActiveOptionChange.bind(this)
    this.handleNDAOptionChange = this.handleNDAOptionChange.bind(this)
    this.handleApprovedOptionChange = this.handleApprovedOptionChange.bind(this)
    this.state = {
      id: 0,
      first_name: '',
      last_name: '',
      phone_number_code: '',
      phone_number: '',
      email: '',
      bio: '',
      zip: '',
      is_disabled: '',
      is_nda_accepted: '',
      is_approved: '',
      organization_id: [],
      selectedOrganisationIds: [],
      initialOrganization: [],
      organizationRequestIds: [],
      organizationList: [],
      orgRequestIds: [],
      space_id: [],
      selectedSpaceIds: [],
      initialSpace: [],
      spaceRequestIds: [],
      spaceList: [],
      loading: false,
      searchOrgText: '',
      searchSpaceText: ''
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  submitFormEdit = e => {
    e.preventDefault()
    this.addOrganisation()
    this.addUserSpace()
    this.setState({ loading: true })
    fetch(process.env.REACT_APP_API_ENDPOINT + '/users/update', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      },
      body: JSON.stringify({
        id: this.state.id,
        first_name: escape(this.state.first_name),
        last_name: escape(this.state.last_name),
        phone_number_code: this.state.phone_number_code,
        phone_number: this.state.phone_number,
        email: this.state.email,
        bio: escape(this.state.bio),
        zip: escape(this.state.zip),
        is_disabled: this.state.is_disabled,
        is_nda_accepted: this.state.is_nda_accepted,
        is_approved: this.state.is_approved,
        organization_id: this.props.item.organization_id

      })
    })
      .then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          message.success('User Updated Successfully')
          this.props.updateState(item.response_objects)
          this.props.toggle()
          this.setState({ loading: false })
        } else {
          message.error(item.message)
        }
      })
      .catch(err => {
        message.error(err)
      })
  }

  handleActiveOptionChange (event) {
    this.setState({
      is_disabled: event.target.value
    })
  }

  handleNDAOptionChange (event) {
    this.setState({
      is_nda_accepted: event.target.value
    })
  }

  handleApprovedOptionChange (event) {
    this.setState({
      is_approved: event.target.value
    })
  }

  handleInputOrgChange (searchOrg) {
    this.setState({
      searchOrgText: searchOrg
    })
    this.getOrganization(searchOrg)
  }

  handleInputSpaceChange (searchSpace) {
    this.setState({
      searchSpaceText: searchSpace
    })
    this.getSpace(searchSpace)
  }

  onSelectOrg = e => {
    const orgSelected = []
    if (e) {
      e.forEach((value) => { orgSelected.push(value.id) })
      this.setState({ organization_id: e, selectedOrganisationIds: orgSelected })
    } else {
      this.setState({ organization_id: '', selectedOrganisationIds: orgSelected })
    }
  }

  onSelectSpace = e => {
    const spaceSelected = []
    if (e) {
      e.forEach((value) => { spaceSelected.push(value.id) })
      this.setState({ space_id: e, selectedSpaceIds: spaceSelected })
    } else {
      this.setState({ space_id: '', selectedSpaceIds: spaceSelected })
    }
  }

  // Get Active Orgs
  getOrganization (searchText) {
    if (searchText && searchText.length >= CONSTANTS.searchTextLength) {
      fetch(process.env.REACT_APP_API_ENDPOINT + `/orgs/getAllActive?searchText=${searchText}&pageSize=${CONSTANTS.pageLimit}`, {
        method: 'get',
        headers: {
          Authorization: window.localStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(items => {
          if (Array.isArray(items.response_objects)) {
            const options = items.response_objects.map((data) => {
              return { id: data.id, value: data.name, label: data.name }
            }
            )
            this.setState({
              organizationList: options
            })
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
    } else if (!searchText) {
      fetch(process.env.REACT_APP_API_ENDPOINT + `/orgs/getAllActive?searchText=${searchText}&pageSize=${CONSTANTS.pageLimit}`, {
        method: 'get',
        headers: {
          Authorization: window.localStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(items => {
          if (Array.isArray(items.response_objects)) {
            const options = items.response_objects.map((data) => {
              return { id: data.id, value: data.name, label: data.name }
            }
            )
            this.setState({
              organizationList: options
            })
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
    }
  }

  // Get Active Spaces
  getSpace (searchText) {
    if (searchText && searchText.length >= CONSTANTS.searchTextLength) {
      fetch(process.env.REACT_APP_API_ENDPOINT + `/space/getAllActiveSpaces?searchText=${searchText}&pageSize=${CONSTANTS.pageLimit}`, {
        method: 'get',
        headers: {
          Authorization: window.localStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(items => {
          if (Array.isArray(items.response_objects)) {
            const options = items.response_objects.map((data) => {
              return { id: data.id, value: data.name, label: data.name }
            }
            )
            this.setState({
              spaceList: options
            })
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
    } else if (!searchText) {
      fetch(process.env.REACT_APP_API_ENDPOINT + `/space/getAllActiveSpaces?searchText=${searchText}&pageSize=${CONSTANTS.pageLimit}`, {
        method: 'get',
        headers: {
          Authorization: window.localStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(items => {
          if (Array.isArray(items.response_objects)) {
            const options = items.response_objects.map((data) => {
              return { id: data.id, value: data.name, label: data.name }
            }
            )
            this.setState({
              spaceList: options
            })
          } else {
            console.log('failure')
          }
        })
        .catch(err => console.log(err))
    }
  }

  // Get Active Orgs By UserId
  getOrganizationByUserId = (id) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + `/users/getOrganizationByUserId/${id}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          const orgData = []
          item.response_objects.map((value) => {
            const options = { id: value.organization_id, value: value.name, label: value.name }
            this.state.selectedOrganisationIds.push(value.organization_id)
            this.state.orgRequestIds.push(value.organization_id)
            orgData.push(options)
          })
          this.setState({
            initialOrganization: item.response_objects,
            organization_id: orgData
          })
        }
      }).catch(err => console.log(err))
  }

  addOrganisation = () => {
    const organization_id = this.state.organization_id
    const organizationRequest = []
    if (this.state.initialOrganization && this.state.initialOrganization.length > 0) {
      this.state.initialOrganization.map((value) => {
        this.state.selectedOrganisationIds.push({ id: value.id, organization_id: value.organization_id, is_disabled: true })
      })
      this.state.selectedOrganisationIds.map((value) => {
        if (value.is_disabled) {
          organizationRequest.push(value)
        } else {
          if (this.state.orgRequestIds.indexOf(value) >>> -1 !== true) {
            organizationRequest.push({ id: '', organization_id: value, is_disabled: false })
          }
        }
      })
    } else {
      organization_id.map((value) => {
        organizationRequest.push({ id: '', organization_id: value.id, is_disabled: false })
      })
    }
    if (organizationRequest && organizationRequest.length > 0) {
      fetch(process.env.REACT_APP_API_ENDPOINT + '/users/addOrganisation', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          organisationIds: organizationRequest,
          user_id: this.state.id
        })
      }).then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {

          }
        }).catch(err => console.log(err))
    }
  }

  // Get Active Spaces By UserId
  getSpacesByUserId = (id) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + `/users/getSpacesByUserId/${id}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(item => {
        if (item.message === 'Success.') {
          const spaceData = []
          item.response_objects.map((value) => {
            const options = { id: value.space_id, value: value.name, label: value.name }
            this.state.selectedSpaceIds.push(value.space_id)
            this.state.spaceRequestIds.push(value.space_id)
            spaceData.push(options)
          })
          this.setState({
            initialSpace: item.response_objects,
            space_id: spaceData
          })
        }
      }).catch(err => console.log(err))
  }

  // Add or Remove Spaces for given userID
  addUserSpace = () => {
    const space_id = this.state.space_id
    const spaceRequest = []
    if (this.state.initialSpace && this.state.initialSpace.length > 0) {
      this.state.initialSpace.map((value) => {
        this.state.selectedSpaceIds.push({ id: value.id, space_id: value.space_id, is_disabled: true })
      })
      this.state.selectedSpaceIds.map((value) => {
        if (value.is_disabled) {
          spaceRequest.push(value)
        } else {
          if (this.state.spaceRequestIds.indexOf(value) >>> -1 !== true) {
            spaceRequest.push({ id: '', space_id: value, is_disabled: false })
          }
        }
      })
    } else {
      if (space_id) {
        space_id.map((value) => {
          spaceRequest.push({ id: '', space_id: value.id, is_disabled: false })
        })
      }
    }
    if (spaceRequest && spaceRequest.length > 0) {
      fetch(process.env.REACT_APP_API_ENDPOINT + '/users/addUserSpace', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.localStorage.getItem('token')
        },
        body: JSON.stringify({
          spaceIds: spaceRequest,
          user_id: this.state.id
        })
      }).then(response => response.json())
        .then(item => {
          if (item.message === 'Success.') {

          }
        }).catch(err => console.log(err))
    }
  }

  componentDidMount () {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const { id, first_name, last_name, phone_number_code, phone_number, email, bio, zip, is_disabled, is_nda_accepted, is_approved, organization_id } = this.props.item
      this.getOrganizationByUserId(id)
      this.getSpacesByUserId(id)
      this.getOrganization(this.state.searchOrgText)
      this.getSpace(this.state.searchSpaceText)
      this.setState({ id, first_name, last_name, phone_number_code, phone_number, email, bio, zip, is_disabled, is_nda_accepted, is_approved, orgId: organization_id })
    }
  }

  render () {
    return (
      this.state.loading
        ? <div id="loadingContent">
          <img align="absmiddle" alt="" src="../images/loading.gif" />
        </div>
        : <Container>
          <Form className="form-horizontal">
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label for="organization_id" className="control-label col-sm-2">Organization</Label>
                  <div className="col-sm-12">
                    {/* <select name="organization_id" className="form-control" onChange={this.handleDropdownChange} value={this.state.organization_id === null ? '' : this.state.organization_id}>
                    <option value="">Select Organization</option>
                    {this.state.organizationList}
                  </select> */}
                    <Select
                      options={this.state.organizationList && this.state.organizationList.length ? this.state.organizationList : []}
                      value={this.state.organization_id}
                      onChange={this.onSelectOrg}
                      isMulti
                      isSearchable={true}
                      onInputChange={(inputText) => this.handleInputOrgChange(inputText)}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">
                <FormGroup>
                  <Label for="space_id" className="control-label col-sm-2">Spaces</Label>
                  <div className="col-sm-12">
                    <Select
                      options={this.state.spaceList && this.state.spaceList.length ? this.state.spaceList : []}
                      value={this.state.space_id}
                      onChange={this.onSelectSpace}
                      isMulti
                      isSearchable={true}
                      onInputChange={(inputText) => this.handleInputSpaceChange(inputText)}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">

                <FormGroup>
                  <Label for="email" className="control-label col-sm-2">Email</Label>
                  <div className="col-sm-12"><Input type="text" name="email" id="email" onChange={this.onChange} value={this.state.email === null ? '' : this.state.email} readOnly /></div>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label for="first_name" className="control-label col-sm-8">First Name</Label>
                  <div className="col-sm-12"><Input type="text" name="first_name" id="first_name" onChange={this.onChange} value={this.state.first_name === null ? '' : this.state.first_name} /></div>
                </FormGroup>
              </Col>
              <Col xs="6">

                <FormGroup>
                  <Label for="last_name" className="control-label col-sm-8">Last Name</Label>
                  <div className="col-sm-12"><Input type="text" name="last_name" id="last_name" onChange={this.onChange} value={this.state.last_name === null ? '' : this.state.last_name} /></div>
                </FormGroup>
              </Col>

              <Col xs="6">

                <FormGroup>
                  <Label for="phone" className="control-label col-sm-8">Phone Number</Label>
                  <div className="col-sm-12">
                    <PhoneInput
                      inputProps={{
                        name: 'phone_number',
                        style: { width: '220px' }
                      }}
                      onChange={this.handleOnChange} value={this.state.phone_number === null ? '' : (this.state.phone_number_code + this.state.phone_number)}
                      disabled />

                  </div>
                </FormGroup>
              </Col>

              <Col xs="6">

                <FormGroup>
                  <Label for="bio" className="control-label col-sm-4">Bio</Label>
                  <div className="col-sm-12"><textarea className="form-control" name="bio" id="bio" onChange={this.onChange} value={this.state.bio === null ? '' : this.state.bio} /></div>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label for="zip" className="control-label col-sm-8">Zip</Label>
                  <div className="col-sm-12"><Input type="text" name="zip" id="zip" onChange={this.onChange} value={this.state.zip === null ? '' : this.state.zip} /></div>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label for="Accept" className="control-label col-sm-8">Approved</Label>
                  <div className="col-sm-12">
                    <input type="radio" value="true" name="approved" id="approvedYes" checked={this.state.is_approved === true || this.state.is_approved === 'true'} onChange={this.handleApprovedOptionChange} /> Yes
                	&nbsp;
                	<input type="radio" value="false" name="approved" id="approvedNo" checked={this.state.is_approved === false || this.state.is_approved === 'false'} onChange={this.handleApprovedOptionChange} /> No
	            </div>
                </FormGroup>

              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label for="Active" className="control-label col-sm-4">Active</Label>
                  <div className="col-sm-12">
                    <input type="radio" value="false" name="active" id="activeYes" checked={this.state.is_disabled === false || this.state.is_disabled === 'false'} onChange={this.handleActiveOptionChange} /> Yes
                    &nbsp;
                  	<input type="radio" value="true" name="active" id="activeNo" checked={this.state.is_disabled === true || this.state.is_disabled === 'true'} onChange={this.handleActiveOptionChange} /> No
                  </div>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label for="Accept" className="control-label col-sm-8">NDA-Accept</Label>
                  <div className="col-sm-12">
                    <input type="radio" value="acceptfalse" name="accept" id="acceptYes" checked={this.state.is_nda_accepted === true || this.state.is_nda_accepted === 'true'} onChange={this.handleNDAOptionChange} disabled /> Yes
                	&nbsp;
                	<input type="radio" value="accepttrue" name="accept" id="acceptNo" checked={this.state.is_nda_accepted === false || this.state.is_nda_accepted === 'false'} onChange={this.handleNDAOptionChange} disabled /> No
	            </div>
                </FormGroup>

              </Col>
            </Row>

            <FormGroup>
              <div className="col-sm-2"></div>
              <div className="col-sm-10"><Button className="btn-success" onClick={this.props.item ? this.submitFormEdit : this.submitFormAdd}>Submit</Button></div>
            </FormGroup>

          </Form>

        </Container>
    )
  }
}

export default AddEditForm
